'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function loadFilters ()
	{
		wrapper.get ('show').success (showsSuccess.bind (this)).error (error=>console.log (error));

		function showsSuccess ({data})
			{
				this.shows=data ?? {};
				wrapper.get ('program_manager').success (programManagersSuccess.bind (this)).error (error=>console.log (error));

				function programManagersSuccess ({data})
					{
						this.program_managers=data ?? {};
						wrapper.get ('playlist/statuses').success (statusesSuccess.bind (this)).error (error=>console.log (error));
					}

				function statusesSuccess ({data})
					{
						this.statuses=data ?? {};
						this.$nextTick (()=>this.$nextTick (()=>this.$nextTick (()=>this.$nextTick (()=>this.filtersLoaded=true))));
					}
			}
	}
