'use strict';
export default function data ()
	{
		return (
			{
				shows:{},
				program_managers:{},
				statuses:{},
				filtersLoaded:false,
			});
	}
