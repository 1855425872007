'use strict';
import mixin      from "../-mixin/mixin";
import data       from './js/data';
import computed   from './js/computed';
import methods    from './js/methods';
import created    from './js/created';
import components from './js/components';

export default (
	{
		mixins:[mixin],
		name:'Channels',
		data,
		computed,
		methods,
		created,
		components,
	});
