'use strict';
import popupClose   from './methods/popupClose';
import popupSuccess from './methods/popupSuccess';
import view         from './methods/view';
import edit         from './methods/edit';
import deleteObject from './methods/deleteObject';
import control      from './methods/control';
import action       from './methods/action';
import cell         from './methods/cell';
import tableLoaded  from './methods/tableLoaded';

export default (
	{
		popupClose,
		popupSuccess,
		view,
		edit,
		deleteObject,
		control,
		action,
		cell,
		tableLoaded,
	});
