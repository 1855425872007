<div>
	<div id="moodfactory-controls">
		<VueFormTabs :tabs="factoryTabs" v-model="factoryTab"/>
		<div id="moodfactory-buttons">
			<button v-if="canEdit" v-text="isEditMode?'Add/Replace tracks':'Edit factory'" @click="isEditMode=!isEditMode"/>
			<template v-if="factoryTab && !isEditMode">
				<button @click="pushTracks ('post')">Submit tracks to mood factory</button>
				<button @click="pushTracks ('put')">Add tracks to mooad factory</button>
			</template>
		</div>
	</div>
	<template v-if="factoryTab">
		<FactoryView v-if="viewFactoryView" :factory="factories[factoryTab]" @loaded="factoryLoaded" @updated="loadFactories"/>
		<ChapterTracks v-if="viewTable" :tableMixin="table" @load="tableLoaded" @reorder="reorderTracks" @click="onClickList" @reload="reloadTable"/>
	</template>
</div>
