'use strict';
export default function stop ()
	{
		return new Promise (resolve=>
			{
				let audio=this.audio;
				audio.context.resume ();
				audio.startedAt=null;
				clearInterval (audio.positionInterval);
				audio.positionInterval=null;
				audio.position=0;
				this.$nextTick (()=>
					{
						setTimeout (()=>
							{
								audio.mode=null;
								resolve ();
							},50);
					});
			});
	}
