'use strict';
export default function loadSuccess ({data,appends})
	{
		data??={};
		if (!Array.isArray (data.program_managers)) data.program_managers=[];
		data.program_managers=data.program_managers.map (program_manager=>
			{
				this.checked[program_manager.id]=true;
				return program_manager.id;
			});
		this.formData=data;
		this.program_managers=appends?.program_managers??{};
		this.jingles=appends?.jingles??{};
		this.schedule_frequency=appends?.schedule_frequency??{};
	}
