'use strict';
import actionView     from './methods/actionView';
import cell           from './methods/cell';
import cellCustomizer from './methods/cellCustomizer';

export default (
	{
		actionView,
		cell,
		cellCustomizer,
	});
