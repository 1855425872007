'use strict';
import pathInfo     from './computed/pathInfo';
import stage        from './computed/stage';
import activatePath from './computed/activatePath';
import browserPopup from './computed/browserPopup';

export default (
	{
		pathInfo,
		stage,
		activatePath,
		browserPopup,
	});
