'use strict';

export default function dragstart (event,rowIndex)
	{
		if (!event.target?.closest ('tr')) return;
		this.isDragging=true;
		this.from=rowIndex;
		window.addEventListener ('mousemove',this.updateDragPosition);
		window.addEventListener ('mouseleave',this.dragend);
		window.addEventListener ('mouseup',this.dragend);
	}
