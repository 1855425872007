'use strict';
import mixin   from '../../-mixin/mixin';
import methods from './js/methods';

export default (
	{
		mixins:[mixin],
		name:'PopupUnbindAll',
		methods,
	});
