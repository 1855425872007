'use strict';
export default function data ()
	{
		return (
			{
				shows:{},
				playlists:{},
				days:['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
			});
	}
