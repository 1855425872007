<div v-if="childrenList" class="vue-menu-group">
	<div class="vue-menu-group-caption">
		<VueIcon v-if="icon" v-bind="{icon}"/>
	</div>
	<div class="vue-menu-group-items">
		<template v-for="item in children">
			<MenuItem v-if="!item.hidden" v-bind="item"/>
		</template>
	</div>
</div>
<RouterLink v-else :to="route">
	<VueIcon v-if="icon" v-bind="{icon,title}" class="vue-menu-icon"/>
	<span v-text="title"/>
</RouterLink>
