'use strict';
export default function data ()
	{
		return (
			{
				photos:
					{
						photo:{previewSize:1000,size:'500x500',title:'WEB - curator catalog'},
						small_photo:{previewSize:700,size:'340x340',title:'WEB - curator solo'},
						photo_app_radio:{previewSize:300,size:'140x140',title:'app (radio)'},
						photo_app_large:{previewSize:300,size:'407x272',title:'app (Large)'},
						photo_app_archives:{previewSize:200,size:'75x75',title:'app (archives)'},
						photo_dashboard:{previewSize:300,size:'140x140',title:'dashboard'},
						share_cover:{previewSize:500,size:'1000x1000',title:'share cover'},
					}
			});
	}
