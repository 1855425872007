'use strict';
export default function popupBind ()
	{
		let id=this.object?.id;
		let src=id?`news_category/${id}/edit`:'news_category/create';
		let dst=id?`news_category/${id}`:'news_category';
		let formData=this.formData;
		let method=id?'put':'post';
		return {src,dst,formData,method};
	}
