'use strict';
import props    from './js/props';
import computed from './js/computed';

export default (
	{
		name:'MixerControls',
		props,
		computed,
	});
