'use strict';
export default function paginator ()
	{
		let page=this.current;
		let count=this.total;
		let pages=[];
		let btns=
			[
				1,2,
				10,20,
				100,200,
				1000,2000,
				((page-1000)/1000).toFixed (0)*1000,
				((page-100)/100).toFixed (0)*100,
				((page-10)/10).toFixed (0)*10,
				page-1,page,page+1,
				((page+10)/10).toFixed (0)*10,
				((page+100)/100).toFixed (0)*100,
				((page+1000)/1000).toFixed (0)*1000,
				((count-1000)/1000).toFixed (0)*1000,
				((count-100)/100).toFixed (0)*100,
				((count-10)/10).toFixed (0)*10,
				count-1,count,
			];

		for (let i=1; i<=count; i++)
			{
				if (btns.includes (i))
					{
						pages.push (i);
						continue;
					}
				if (pages.slice (-1)[0]!==null) pages.push (null);
			}
		return pages;
	}
