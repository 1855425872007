<VuePopupEdit v-bind="popupBind" v-on="popupEvents">
	<VueFormInput v-model="formData.title" v-model:e="errors.title">Title</VueFormInput>
	<VueFormInput v-model="formData.subtitle" v-model:e="errors.subtitle">Subtitle</VueFormInput>
	<VueFormSelect v-if="Object.keys (categories).length>1" :options="categories" v-model="formData.category_id" v-model:e="errors.category_id">News category</VueFormSelect>
	<div v-else v-text="`Category: ${categories[formData.category_id]}`"/>
	<VueFormInput v-model="formData.text" v-model:e="errors.text" type="area">Text</VueFormInput>
	<div class="view-photo-wrapper">
		<VueFormImage :image="formData.picture" class="popup-view-photo"/>
		<VueIcon v-if="formData.picture" :icon="`\uf2ed`" @click="delete (formData.picture)" title="Delete picture"/>
		<VueFormFile @select="selectPicture" @click="delete (errors.picture)" accept="image/*" class="view-choose-file">
			<VueIcon :icon="`\uf0ee`"/>
			Select picture
		</VueFormFile>
		<div v-if="errors.picture" v-text="errors.picture[0]??errors.picture" @click="delete (errors.picture)"/>
	</div>
</VuePopupEdit>
