'use strict';
export default function buttonBind (mixType)
	{
		let inMixer=this.inMixers.includes (mixType);
		let hasMix=this.hasMix (mixType);
		let makingMix=!hasMix && inMixer;
		let isCurrentMix=this.mix?.type===mixType;
		let playReady=this.playReady;
		let status=this.formData.status;
		let enablingStatuses=['Ready for submit'];
		if (['Admin','Program manager'].includes (USER.role)) enablingStatuses.push ('Pending approval');
		let noNIXER=mixType==='MIXER' && USER.role!=='Admin';
		let disabled=(!hasMix && this.inMixers.length) || !(this.allowMix && playReady) || status==='Draft' || (!hasMix && !enablingStatuses.includes (status)) || noNIXER;
		let classList=['playlist-popup-view-inline-button'];
		if (makingMix || (!playReady && isCurrentMix)) classList.push ('playlist-popup-view-inline-button-busy');
		if (hasMix) switch (this.playMode)
			{
				case null:
				case 'pause':
					classList.push (`playlist-popup-view-button-play`);
					break;
				case 'play':
					classList.push (isCurrentMix?`playlist-popup-view-button-pause`:`playlist-popup-view-button-play`);
					break;
			}
		return {disabled,'class':classList.join (' ')};
	}
