'use strict';
export default function allowSubmit ()
	{
		let status=this.formData.status;
		if (this.inMixers.length || !this.formData.mixes?.length) return false;
		switch (USER.role)
			{
				case 'Program manager':
				case 'Admin':
					return (this.formData.mixes?.length<2 || this.mixForSubmit) && ['Ready for submit','Pending approval'].includes (status);
				case 'Curator':
					return status==='Ready for submit';
				default:
					return false;
			}
	}
