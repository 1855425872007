'use strict';
import axios from "axios";

let freeze;
let oldBody=null;
let reload;
export default function (force)
	{
		this.$nextTick (()=>
			{
				let binds=this.binds;
				let controls=Object.keys (this.controls);
				let actions=Object.keys (this.actions);
				let bindActions=this.bindActions;
				let filter=this.filter;
				let invertedFilter=this.invertedFilter;
				let freeSearch=this.freeSearch;
				let columns=this.data;
				let order=this.order;
				let pages=this.pages;
				let page=pages.current;
				let perPage=this.pages.perPage;
				let body={binds,controls,actions,bindActions,filter,invertedFilter,freeSearch,columns,order,page,perPage};
				let newBody=JSON.stringify (body);
				if (force || oldBody!==newBody || reload!==this.reload)
					{
						reload=this.reload;
						if (freeze) return;
						freeze=true;
						this.preloader=true;
						this.checked=[];
						this.$emit ('loadStart');
						oldBody=newBody;
						axios.post (this.url,body).then (success.bind (this))
							.catch (error.bind (this)).finally (response.bind (this));
					}
				
				function success ({data})
					{
						let response=data;
						this.error=null;
						this.rows=response.rows;
						let counts=response.counts ?? {total:0,filtered:0};
						this.counts=counts;
						this.allowedControls=response.controls.reduce (reduceControls.bind (this),{});
						let totalPages=response.pages.total;
						pages.total=totalPages;
						if (totalPages<pages.current) this.$nextTick (()=>pages.current=totalPages);
						data=
							{
								request:body,
								rows:response.rows.map (row=>row.row),
								filteredKeys:response.filteredKeys,counts,
								tableFilter:this.filter,
							};
						this.$emit ('load',data);
						
						function reduceControls (controls,control)
							{
								controls[control]=this.controls[control];
								return controls;
							}
					}
				
				function error (error)
					{
						console.log (error);
						if (Math.between (error.status,400,499)) this.error=error.data.message;
					}
				
				function response ()
					{
						this.preloader=false;
						freeze=false;
						this.$emit ('loadEnd');
					}
			});
	}
