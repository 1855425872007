'use strict';
export default function submitError ({data})
	{
		let errors=data.errors??{};
		let links=[];
		Object.keys (errors).forEach (key=>
			{
				let match=key.match (/^links\.(\d+)$/);
				if (!match) return;
				links[parseInt (match[1])]=errors[key];
				delete (errors[key]);
			});
		errors.links=links;
		this.errors=errors;
	}
