'use strict';

import {isPlainObject} from "lodash/lang";

export default function updatePlayNow ({playnow})
	{
		let {live,moods}=playnow;
		live.show=live.show?.title;
		let channel=this.channels[live.channel];
		if (channel) channel.playNow=live;
		Object.keys (moods).forEach (k=>
			{
				let mood=moods[k];
				if (isPlainObject (mood))
					{
						let channel=this.channels[mood.channel];
						if (channel) channel.playNow=mood;
					}
			});
	}
