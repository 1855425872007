'use strict';
export default function dragmove (event)
	{
		this.dragPosition.x=event.clientX;
		this.dragPosition.y=event.clientY;
		let to=document.elementFromPoint (event.clientX,event.clientY)?.closest ('tr');
		if (!(this.isDragging && to)) return;
		let tbody=to.closest ('tbody');
		let rows=Array.from (tbody.children);
		let toIndex=rows.indexOf (to);
		let toRect=to.getBoundingClientRect ();
		let cursorY=event.clientY;
		let midY=toRect.top+toRect.height/2;
		this.to=toIndex;
		this.downDrag=cursorY>midY;
		if (this.from===null)
			{
				let from=event.target.closest ('tr');
				this.from=Array.from (from.closest ('tbody').children).indexOf (from);
			}
		let scrollContainer=tbody.closest ('.vue-table-body');
		let rect=scrollContainer.getBoundingClientRect ();
		let scrollSpeed=10;
		let scrollOffset=50;
		if (event.clientY>rect.bottom-scrollOffset) scrollContainer.scrollTop+=scrollSpeed;
		else if (event.clientY<rect.top+scrollOffset) scrollContainer.scrollTop-=scrollSpeed;
	}
