'use strict';
import {VueHelperPreloader} from 'bomber-net-vue-forms/helpers';
import ChapterTracks        from '../../../../chapters/crm/Tracks/Tracks.vue';
import UploadTracks         from '../../../tracks/PopupCreate/PopupCreate.vue';
import PopupReedit          from '../../PopupReedit/PopupReedit.vue';
import PopupDecline         from '../../PopupDecline/PopupDecline.vue';
import PopupDeleteMixes     from '../../PopupDeleteMixes/PopupDeleteMixes.vue';
import PopupReorder         from '../../PopupReorder/PopupReorder.vue';

export default (
	{
		VueHelperPreloader,
		ChapterTracks,
		UploadTracks,
		PopupReedit,
		PopupDecline,
		PopupDeleteMixes,
		PopupReorder,
	});
