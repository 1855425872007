'use strict';
export default function setOriginRoute ()
	{
		return new Promise ((resolve,reject)=>
			{
				let route=window.location.pathname;
				let query={};
				for (let [k,v] of new URL (window.location).searchParams.entries ()) query[k]=v;
				Router.push ({path:route?route:'/',query});
				resolve ();
			});
	}
