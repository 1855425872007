'use strict';
import data       from './js/data';
import computed   from './js/computed';
import components from './js/components';

export default (
	{
		name:'CRMUser',
		data,
		computed,
		components,
	});
