'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function submit ()
	{
		let url=`mood_factory/${this.binds.mood_factory}/track`;
		let _method='DELETE';
		let tracks=this.checked.map (track=>track.id);
		wrapper.post (url,{tracks,_method:'DELETE'}).success (()=>this.$emit ('submitSuccess'));
	}
