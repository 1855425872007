<!--<VuePopupView v-if="showPopup" class="maintenance-popup" @close="showPopup=false">-->
<!--	<div class="maintenance-message">-->
<!--		<p>Due to maintenance, the music dashboard will be unavailable for the next hour.</p>-->
<!--		<p>We're sorry for the inconvenience, thank you for your patience.</p>-->
<!--		<p>The ROVR Team</p>-->
<!--	</div>-->
<!--	<button type="reset" class="maintenance-button"/>-->
<!--</VuePopupView>-->
<template v-if="!error">
	<CRMMain v-if="userObject"/>
	<CRMLogin v-else-if="userObject===null" @success="load"/>
</template>
<div v-else v-html="error"/>
