'use strict';
import mixin    from '../../-mixin/mixin';
import data     from './js/data';
import computed from './js/computed';
import watch    from './js/watch';
import methods  from './js/methods';

export default (
	{
		mixins:[mixin],
		name:'JinglePopupEdit',
		data,
		computed,
		watch,
		methods,
	});
