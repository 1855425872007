'use strict';
import components from "../../../../../-mixin/js/components";
import props      from './js/props';
import data       from './js/data';
import methods    from './js/methods';

export default (
	{
		name:'ProgramManager',
		props,
		data,
		methods,
		components,
	});
