'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function showId (id)
	{
		wrapper.get (`show/${id}`).success (({data})=>
			{
				if (!data) data={playlists:[]};
				let playlists=data.playlists.filter (i=>['Ready for schedule','Scheduled'].includes (i.status)).reduce ((c,i)=>
					{
						c[i.id]=`#${i.id} - ${i.title}`;
						return c;
					},{});
				this.playlists=playlists;
				playlists=Object.keys (playlists);
				if (playlists.length<2) this.formData.playlist_id=playlists[0];
			});
	}
