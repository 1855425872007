'use strict';
import {snakeCase} from 'lodash/string';

export default function data ()
	{
		return (
			{
				errors:
					{
						links:[],
					},
				roles:{},
				program_managers:{},
				shows:{},
				path:snakeCase (window.location.pathname.slice (1,-1)),
			});
	}
