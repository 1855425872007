'use strict';
export default function table ()
	{
		let url=`schedule/table`;
		let data=['#','id','show','program_manager','playlist','day','start','end'];
		let columns=
			{
				'#':'#',
				id:{title:'ID',order:0},
				show:'Show',
				program_manager:'Program manager',
				playlist:'Playlist',
				day:'Day',
				start:'Start',
				end:'End',
			};
		let filters=
			{
				day:{title:'Day',type:'daterange'}
			};
		let controls={create:'Create'};
		let actions=
			{
				edit:{title:'Edit',icon:`\uf044`},
				delete:{title:'Delete',icon:`\uf2ed`},
			};
		return ({url,data,columns,filters,controls,actions});
	}
