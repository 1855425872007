'use strict';
export default function data ()
	{
		return (
			{
				player:{view:false,progress:0,mode:null,current:null},
				tracks:[],
				checked:[],
			});
	}
