'use strict';
import {toPairs} from "lodash/object";
import {isPlainObject} from "lodash/lang";

export default function created ()
	{
		let defaults={};
		toPairs (this.filters).forEach ((name,filter)=>
			{
				if (isPlainObject (filter) && filter.default) defaults[key]=filter.default;
			})
		this.$emit ('update:modelValue',defaults);
	}
