<div v-if="rows.length" class="vue-table-body" :class="{'is-dragging':isDragging}" @click="handleClickOutside">
	<table @mouseup.left.exact="dragend">
		<thead>
			<tr>
				<th v-if="checkboxes" class="vue-table-checkbox-title"/>
				<th v-if="reorder" class="vue-table-drag-title"/>
				<th v-for="(column, name) in titles" @click="switchOrder (name)" :class="titleClass (name)">
					<span v-if="data.includes (name)" :class="orderColumn (name)" v-text="column"/>
				</th>
				<th v-if="Object.keys(actions).length" class="vue-table-title-actions">Actions</th>
			</tr>
		</thead>
		<tbody @mousemove="dragmove" @mouseup.left.exact.stop.prevent="dragend">
			<tr v-for="(row, rowIndex) in rows" v-customize:[customizers.row]="{row,rowIndex}" @click="handleRowClick (rowIndex)" :class="rowClass (rowIndex)">
				<td v-if="checkboxes" class="vue-table-checkbox-cell">
					<VueFormCheck :modelValue="checked.includes(rowIndex)" @update:modelValue="toggleChecked(rowIndex)"/>
				</td>
				<td v-if="reorder" class="vue-table-drag-cell" title="Move Track (Hold shift or command to select multiple tracks)" @mousedown.left.exact.stop.prevent="dragstart($event, rowIndex)">
					<VueIcon :icon="`\uf0c9`"/>
				</td>
				<td v-for="(column, name) in titles" v-customize:[customizers.cell]="{ cell: row.row[name], name, row, rowIndex }" :class="cellClass(name)" @click="handleRowClick(rowIndex)">
					<slot v-if="columns[name].isSlot" v-bind="row"/>
					<template v-else-if="data.includes(name)">
						<template v-for="cell in [row.row[name]]">
							<template v-if="typeof(cell) === 'object'">
								<div v-for="(subcell, subname) in cell" v-html="isFinite(subname) ? subcell : `${subname}: ${subcell}`"
									 @click="cellClick(cell, name, rowIndex)" :title="isFinite(subname) ? subcell : `${subname}: ${subcell}`"
									 :class="isFinite(subname) ? `vue-table-cell-element-${subcell}` : `vue-table-cell-element-${subname}`"
									 class="vue-table-cell-element"/>
							</template>
							<div v-else v-html="cell" :title="cell" @click="cellClick(cell, name, rowIndex)" class="vue-table-cell-element"/>
						</template>
					</template>
				</td>
				<td v-if="Object.keys(actions).length" class="vue-table-cell-actions">
					<VueIcon v-for="(action, name) in decodeList(allowedActions(row.actions))" :title="action.title" type="button" :icon="action.icon"
							 @click="$emit ('action',name,action,rowIndex)"
							 :class="`vue-table-action-${name}`" class="vue-table-icon-action"/>
				</td>
			</tr>
		</tbody>
		<tfoot>
			<tr/>
		</tfoot>
	</table>
	<div class="vue-table-body-pad"/>
	<teleport to="body">
		<div v-if="isDragging" :style="{top:`${dragPosition.y}px`,left:`${dragPosition.x}px`}" class="drag-icon">
			<VueIcon :icon="`\uf3b5`"/>
		</div>
	</teleport>
</div>
<div v-else>No data</div>
