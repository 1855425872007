'use strict';
import props      from './js/props';
import components from './js/components';

export default (
	{
		name:'NotificationList',
		props,
		components,
	});
