'use strict';
export default function decline ()
	{
		this.lockedReedit=true;
		this.stopPlayMix ().then (()=>
		{
			this.lockedReedit=false;
			this.popup='Decline';
		});
	}
