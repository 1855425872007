<div class="track-play-container">
	<audio v-if="['play','pause'].includes (mode)" autoplay controls controlsList="nodownload noplaybackrate" :src="src" @timeupdate="progress" @ended="$emit ('ended')"
		   @play="switchMode ('play')" @pause="switchMode ('pause')" ref="player"/>
	<div class="track-play-container-line">
		<VueIcon v-if="track" :icon="mode==='play'?`\uf04c`:`\uf04b`" @click="switchMode"/>
		<ProgressBar v-bind="{current,total}" @position="position"/>
	</div>
	<div class="track-play-info">
		<template v-if="track">
			<div v-html="`<span>Title: </span> ${track.title}`"/>
			<div v-html="`<span>Artist: </span> ${track.artist}`"/>
		</template>
	</div>
</div>
