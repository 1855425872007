'use strict';
import mixin      from "../-mixin/mixin";
import computed   from './js/computed';
import methods    from './js/methods';
import components from './js/components';

export default (
	{
		mixins:[mixin],
		name:'StaisticsV2',
		computed,
		methods,
		components,
	});
