'use strict';
import props   from './js/props';
import methods from './js/methods';

export default (
	{
		name:'NotificationItem',
		props,
		methods,
	});
