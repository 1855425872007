<div id="tracks-container">
	<VueTable v-bind="tableDefault" @actionDelete="deleteObject ($event.row)" :checkboxes="tableMixin?.binds?.mood_factory"
		@loadStart="popup=object=null" @actionEdit="edit ($event.row)" @actionView="view ($event.row)" @controlCreate="edit ()"
		@load="tableLoaded" @checked="checked=$event" @control="control" @action="action" @cell="cell">
		<slot/>
		<template #headerSecondRow>
			<PagePlayer v-if="player.view" :hidden="!Object.keys (tableDefault.binds).length" :track="player.current" :mode="player.mode"
						@progress="player.progress=$event" @mode="player.mode=$event" @ended="nextTrack" ref="player"/>
		</template>
		<template #bodyPlayer="track">
			<InlinePlayer v-if="track.uuid_play" v-bind="inlinePlayerBind (track)"
				@play="player.current=track;player.mode='play'" @pause="player.mode='pause'"
				@stop="player.current=player.mode=null" @position="playPosition ($event,track)"/>
		</template>
	</VueTable>
</div>
<component :is="popup" v-if="popup" v-bind="{object,binds:Object.assign (binds,tableMixin.binds),popup,checked}" v-on="popupEvents"/>
