'use strict';
export default function table ()
	{
		let url=`tag/table`;
		let data=['#','id','title','description','color'];
		let columns=
			{
				'#':'#',
				id:{title:'ID',order:0},
				title:{title:'Title',order:0},
				description:{title:'Description',order:0},
				color:'Color',
			};
		let controls=
			{
				create:'Create',
			};
		let actions=
			{
				edit:{title:'Edit',icon:`\uf044`},
				delete:{title:'Delete',icon:`\uf2ed`},
			};
		let customizers=
			{
				cell (el,{name,cell})
					{
						if (name==='color')
							{
								el.innerText='';
								el.style.backgroundColor=cell;
							}
					}
			};
		return ({url,data,controls,columns,actions,customizers});
	}
