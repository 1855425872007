'use strict';
export default function drawWave ()
	{
		let canvas=this.$refs.canvas;
		let {width,height}=canvas;
		let context=canvas.getContext ('2d');
		context.strokeStyle='#000000';
		let audioBuffer=this.audio.buffer;
		let channels=audioBuffer.numberOfChannels;
		let length=audioBuffer.length;
		let chunkSize=Math.round (length/width);
		let shift=Math.floor (height/2);
		context.beginPath ();
		for (let x=0;x<width;x++)
			{
				let offset=x*chunkSize;
				let size=Math.min (chunkSize,length-offset);
				let min,max;
				min=max=0;
				for (let c=0;c<channels;c++)
					{
						let channel=Array.from (audioBuffer.getChannelData (c).slice (offset,offset+size-1));
						channel.forEach (value=>
							{
								if (value>0) max+=value;
									else min+=value;
							});
					}
				size*=channels/2;
				min=shift-Math.sqrt (min/size*-1)*-shift*2;
				max=shift-Math.sqrt (max/size)*shift*2;
				context.moveTo (x,min);
				context.lineTo (x,max);
			}
		context.stroke ();
	}
