'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function loadChapters (routeIndex)
	{
		return new Promise ((resolve,reject)=>
			{
				wrapper.get ('chapters').success (response=>
					{
						try
							{
								if (!Array.isArray (response)) return reject ('Invalid chapters structure');
								let map={};
								let extractChapters=chapters=>
									{
										return chapters.reduce ((chapters,chapter)=>
											{
												let id=chapter.id;
												let route=chapter.route?routeIndex[chapter.route]:null;
												let children=extractChapters (chapter.children);
												delete chapter.id;
												chapter=Object.assign (chapter,{route,children});
												if (route) map[route]=chapter;
												chapters[id]=chapter;
												return chapters;
											},{});
									};
								this.chapters={tree:extractChapters (response),map};
								resolve ();
							} catch (e)
								{
									reject ('Error loading chapters');
								}
					}).error (error=>
						{
							console.log (error);
							reject ('Error loading chapters');
						});
			});
	}
