'use strict';
export default function data ()
	{
		return (
			{
				factories:{},
				viewFactoryView:true,
				factoryTab:null,
				moods:{},
				tags:{},
				tableReload:null,
				viewTable:false,
				filteredTracks:[],
				canEdit:false,
				isEditMode:false,
			});
	}
