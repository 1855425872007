'use strict';
export default function dragMove (event)
	{
		let draggedTrack=this.draggedTrack;
		if (draggedTrack===null || event.buttons!==1) return;
		let tracks=this.playlist.tracks;
		let offset=tracks[draggedTrack].offset;
		if (event.altKey) tracks.forEach (track=>
			{
				if (track.offset>=offset) track.offset+=event.movementX;
			});
			else tracks[draggedTrack].offset+=event.movementX;
	}
