'use strict';
import {upperFirst} from 'lodash/string';

export default function cell (cell,{row})
	{
		let id=row.track_id??row.id;
		switch (cell)
			{
				case 'moods':
				case 'tags':
					this.popup=`PopupAdd${upperFirst (cell)}`;
					break;
				case 'title':
				case 'artist':
				case 'album':
				case 'year':
				case 'label':
				case 'comment':
				case 'bpm':
				case 'key':
				case 'isrc':
					this.popup=`PopupEditMetadata${upperFirst (cell)}`;
					break;
				default:
					return;
			}
		this.object=Object.assign (row,{id});
	}
