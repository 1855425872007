'use strict';
export default function popupBind ()
	{
		let playlist=this.object;
		let src=playlist?`playlist/${playlist.id}/edit`:'playlist/create';
		let dst=playlist?`playlist/${playlist.id}`:'playlist';
		let formData=this.formData;
		let method=playlist?'put':'post';
		return {src,dst,formData,method};
	}
