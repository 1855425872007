'use strict';
import {VueFormFile,VueFormImage,VueFormInput,VueFormSelect,VueFormCheck} from 'bomber-net-vue-forms/elements';

export default (
	{
		VueFormInput,
		VueFormImage,
		VueFormFile,
		VueFormSelect,
		VueFormCheck,
	});
