'use strict';
export default function data ()
	{
		return (
			{
				error:null,
				playlist:{},
				loadedTracksCount:0,
				audio:
					{
						context:null,
						startedAt:null,
						position:0,
						positionInterval:null,
						mode:null,
					},
				controlsEvents:
					{
						play:this.play,
						pause:this.pause,
						stop:this.stop,
					},
				draggedTrack:null,
			});
	}
