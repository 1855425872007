'use strict';
import edit                    from './methods/edit';
import cell                    from './methods/cell';
import cellMoods               from './methods/cellMoods';
import cellTags                from './methods/cellTags';
import controlShuffle          from './methods/controlShuffle';
import controlUnbindAll        from './methods/controlUnbindAll';
import controlExcel            from './methods/controlExcel';
import stop                    from './methods/stop';
import nextTrack               from './methods/nextTrack';
import actionUnbindMoodFactory from './methods/actionUnbindMoodFactory';
import inlinePlayerBind        from './methods/inlinePlayerBind';
import playPosition            from './methods/playPosition';
import actionDownload          from './methods/actionDownload';
import cellCustomizer          from './methods/cellCustomizer';
import rowCustomizer           from './methods/rowCustomizer';
import tableLoaded             from './methods/tableLoaded';

export default (
	{
		edit,
		cell,
		cellMoods,
		cellTags,
		controlShuffle,
		controlUnbindAll,
		controlExcel,
		stop,
		nextTrack,
		actionDownload,
		actionUnbindMoodFactory,
		inlinePlayerBind,
		playPosition,
		cellCustomizer,
		rowCustomizer,
		tableLoaded,
	});
