'use strict';
export default function nextTrack ()
	{
		let index;
		let tracks=this.tracks;
		for (index in tracks)
			{
				let track=tracks[index];
				if (track.uuid===this.player.current.uuid) break;
			}
		index++;
		if (index>=tracks.length)
			{
				this.stop ();
				return;
			}
		this.player.current=tracks[index];
	}
