'use strict';
import title       from './computed/title';
import type        from './computed/type';
import empty       from './computed/empty';
import bind        from './computed/bind';
import filterClass from './computed/filterClass';

export default (
	{
		title,
		type,
		empty,
		bind,
		filterClass,
	});
