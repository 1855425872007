'use strict';
export default function data ()
	{
		return (
			{
				popup:null,
				object:null,
				tableReload:null,
				tableLoadedData:{},
				popupEvents:
					{
						close:this.popupClose,
						submitSuccess:this.popupSuccess,
					},
			});
	}
