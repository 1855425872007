<div v-if="error" v-text="error" class="chapter-load-error"/>
<div v-else>
	<div id="mixer-header">
		<img src="./images/logo.png" alt="">
		<div class="mixer-header-position">{{positionHm}}:<span>{{positionSec}}</span></div>
		<h1 class="mixer-header-title">MIXER</h1>
	</div>
	<div id="mixer-controls">
		<div v-text="playlist.title" class="mixer-playlist-title"/>
		<div class="mixer-controls-buttons">
			<MixerControls v-bind="bindControls" v-on="controlsEvents" class="mixer-controls-playback"/>
		</div>
		<div class="mixer-settings-buttons">
			<MixerSettings class="mixer-settings-buttons-big"/>
		</div>
		<div class="mixer-tools-buttons">
			<MixerTools class="mixer-tools-panel"/>
		</div>
		<div class="mixer-exit">
			<button class="mixer-exit-save">save mix</button>
			<button class="mixer-exit-cancel">cancel</button>
		</div>
	</div>
	<div id="mixer-area">
		<div id="mixer-area-sidebar">
			<div id="mixer-info">
				<div id="mixer-info-duration" v-text="durationHMS"/>
				<div class="mixer-info-more">
					<VueIcon icon="by" :notify="playlist.program_manager?.name" class="program-manager"/>
					<VueIcon icon="Uploaded on" :notify="playlist.created_at" class="upload-date"/>
					<VueIcon icon="Tracks" :notify="playlist.tracks?.length" class="number"/>
					<VueIcon icon="Playlist ID" :notify="playlist.id" class="number"/>
				</div>
			</div>
			<div id="mixer-channels">
				<div class="mixer-channel" v-text="`chan. 01`"/>
				<div class="mixer-channel" v-text="`chan. 02`"/>
			</div>
			<div id="mixer-channel-add">
				<button>+</button>
			</div>
		</div>
		<div id="mixer-worktable" @click.exact.left.stop.prevent="rewind" @mousemove="dragMove" @mouseup.left="dragEnd" @mouseleave="dragEnd">
			<div id="mixer-timeline" :style="timelineStyle">
				<div class="mixer-timeline-inner">
					<span v-text="position"/>
				</div>
			</div>
			<div id="mixer-tracks" :style="timelineStyle">
				<MixerTrack v-for="(track,i) in playlist.tracks" v-bind="bindTrack (track,i)" class="mixer-track"
					@loaded="loadedTracksCount++" @mousedown.left="dragStart (i)"/>
			</div>
			<div id="mixer-playback-cursor" :style="`left:${this.audio.position}px`"/>
		</div>
	</div>
</div>
