'use strict';
import {isString} from 'lodash/lang';

export default function formDataJingle (jingle)
	{
		switch (true)
			{
				case jingle instanceof File:
					let reader=new FileReader ();
					reader.readAsDataURL (jingle);
					reader.onload=()=>this.jingle=reader.result;
					break;
				case isString (jingle):
					this.jingle=jingle;
					break;
				default:
					this.jingle=null;
			}
	}
