'use strict';
import load        from './methods/load';
import selectCover from './methods/selectCover';
import save        from './methods/save';

export default (
	{
		load,
		selectCover,
		save,
	});
