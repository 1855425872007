'use strict';
export default function reorderPlaylist ()
	{
		if (this.formData?.canReorderPlaylist)
			{
				this.lockedReedit=true;
				this.stopPlayMix ().then (()=>
					{
						this.lockedReedit=false;
						this.popup='Reorder';
					});
			}
	}
