'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function mixAction (mixType)
	{
		if (this.hasMix (mixType))
			{
				let isCurrentMix=this.mix?.type===mixType;
				switch (this.playMode)
					{
						case null:
							this.setMix (mixType);
							break;
						case 'play':
							if (isCurrentMix) this.$refs.audio.pause ();
							else this.stopPlayMix ().then (()=>this.setMix (mixType));
							break;
						case 'pause':
							if (isCurrentMix) this.$refs.audio.play ();
							else this.stopPlayMix ().then (()=>this.setMix (mixType));
							break;
					}
			} else
			{
				this.preloader=true;
				this.formData.in_mixers.push ({mixtype:mixType,stage:0});
				wrapper.post (`playlist/${this.formData.id}/automix/${mixType}`).success (()=>
					{
						this.$nextTick (()=>setTimeout ((function ()
							{
								this.preloader=false;
								this.fullReload ();
								if (mixType==='MIXER') window.open (`https://devmixer.techrovr.com?id=${this.object.id}`,'_blank');
							}).bind (this),1000));
					});
			}
	}
