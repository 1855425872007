'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function submit ()
	{
		let mixes=this.formData.mixes;
		if (!Array.isArray (mixes)) return;
		let mix=mixes.length===1||USER.role==='Curator'?mixes[0]:mixes.find (mix=>mix.type===this.mixForSubmit);
		this.preloader=true;
		this.stopPlayMix ().then (()=>
			{
				let url=`playlist/${this.object.id}/submit`;
				if (mix) url+=`/${mix.uuid}`;
				wrapper.post (url)
					.success (()=>
						{
							setTimeout (()=>
								{
									this.reload ();
									this.reloadTable ();
									this.preloader=false;
								},1500)
						});
			});
	}
