'use strict';
import {mapValues}     from 'lodash/object';
import {isPlainObject} from 'lodash/lang';

export default function decodeList (list)
	{
		if (!list) return [];
		if (Array.isArray (list)) return list.map (item=>decodeListItem (item));
		if (isPlainObject (list)) return mapValues (list,decodeListItem);
		return [];

		function decodeListItem (item)
			{
				let flat=!isPlainObject (item);
				let decoded=flat?{}:item;
				decoded.title=flat?item:item.title ?? '';
				decoded.icon=flat?'':item.icon ?? '';
				decoded.type=flat?null:item.type ?? null;
				return decoded;
			}

	}
