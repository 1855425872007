'use strict';
import props      from './js/props';
import computed   from './js/computed';
import components from './js/components';

export default (
	{
		name:'FilterRange',
		props,
		computed,
		components,
	});
