'use strict';
import wrapper from "bomber-net-axios-wrapper";

export default function isrc ()
	{
		this.disabled=true;
		wrapper.get (`track/${this.object.id}/isrc`)
			.success ((response)=>this.formData.isrc=response?response:this.formData.isrc)
			.response (()=>this.disabled=false);
	}
