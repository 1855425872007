'use strict';
export default (
	{
		url:{type:String,required:true},
		data:{type:Array,default:()=>[]},
		columns:{type:Object,default:()=>({})},
		binds:{type:Object,default:()=>({})},
		perPage:{type:Number,default:100},
		filters:{type:Object,default:()=>({})},
		hideFreeSearch:{type:Boolean,default:false},
		controls:{type:Object,default:()=>({})},
		checkboxes:{type:Boolean,default:false},
		actions:{type:Object,default:()=>({})},
		bindActions:{type:Object,default:()=>({})},
		customizers:{type:Object,default:()=>({})},
		reorder:{type:Boolean,default:false},
		unsorted:{type:Boolean,default:false},
		noPreloader:{type:Boolean,default:false},
		reload:Number,
	});
