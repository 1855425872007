'use strict';
export default function table ()
	{
		let url=`client/wait_list`;
		let data=['#','id','name','email'];
		let columns=
			{
				'#':'#',
				id:{title:'ID',order:0},
				name:{title:'Name',order:0},
				email:{title:'Email',order:0},
			};
		let actions={invite:{title:'Invite',icon:`\uf14a`}};
		return ({url,data,columns,actions});
	}
