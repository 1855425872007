'use strict';
import {isPlainObject} from 'lodash/lang';
import {values}        from 'lodash/object';
import {upperFirst}    from 'lodash/string';

export default function cellCustomizer (el,{name,cell})
	{
		switch (name)
			{
				case 'duration':
					el.querySelector ('div').innerText=hms (cell);
					break;
				case 'moods':
				case 'tags':
					let items=[];
					values (el.children).forEach (child=>child.style.color='');
					cell?.forEach ((item,i)=>
					{
						if (isPlainObject (item))
							{
								let child=el.children[i];
								child.innerText=item.title;
								child.title=item.title;
								child.style.color=item.color;
							}
					});
					break;
				case 'alsoFound':
					values (el.children).forEach (child=>
					{
						let text=upperFirst ((child.innerText.split (': ')[0].replace ('-',' ')));
						if (text==='All tracks') text='ROVR Library';
						child.title=text;
						child.innerText=text;
					});
					break;
			}
	}
