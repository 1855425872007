'use strict';
import wrapper from "bomber-net-axios-wrapper";

export default function load ()
	{
		return new Promise (resolve=>
		{
			wrapper.get ('broadcast/channels').success (response=>
				{
					this.channels=response ?? {};
					resolve ();
				});
		});
	}
