'use strict';
export default function table ()
	{
		let url=`track/table`;
		let binds=Object.assign ({},this.binds);
		let inPlaylists=Object.keys (binds).includes ('playlist');
		let data=['#','title','artist','album','year','label','bpm','key','bitrate','duration','comment','playlists','alsoFound','uuid','uuid_play'];
		if (USER.role==='Admin') data.push ('tuc','energy','isrc');
		switch (USER.role)
			{
				case 'Admin':
					data.push ('moods','tags');
					break;
				case 'Program manager':
				case 'Curator':
					data=data.filter (column=>!['album','year','comment','playlists'].includes (column));
					break;
			}
		let order=inPlaylists?null:0;
		let clickable=['Admin','Program manager'].includes (USER.role);
		let columns=
			{
				'#':'#',
				title:{title:'Title',order,clickable},
				artist:{title:'Artist',order,clickable},
				album:{title:'Album',order,clickable},
				year:{title:'Year',order,clickable},
				label:{title:'Label',order,clickable},
				bpm:{title:'BPM',order,clickable},
				key:{title:'Key',order,clickable},
				energy:{title:'Energy',order,clickable},
				bitrate:{title:'Bitrate',order},
				duration:{title:'Duration',order},
				comment:{title:'Notes',order,clickable},
				isrc:{title:'ISRC',order,clickable},
				tuc:{title:'Unique code'},
				moods:{title:'Moods',clickable},
				tags:{title:'Tags',clickable},
				playlists:'Playlists',
				alsoFound:'Also found',
				player:{title:'',isSlot:true},
			};
		if (inPlaylists)
			{
				if (USER.role!=='Admin') delete (columns.tuc);
				if (USER.role==='Program manager')
					{
						delete (columns.energy);
						delete (columns.isrc);
					}
			}
		if (inPlaylists || USER.role!=='Admin') delete (columns.tuc);
		if (USER.role==='Program manager') delete (columns.comment.order);
		let actions=
			{
				delete:{title:'Delete',icon:`\uf2ed`},
				edit:{title:'Edit',icon:`\uf044`},
				download:{title:'Download',icon:`\uf019`},
			};
		let customizers={cell:this.cellCustomizer,row:this.rowCustomizer};
		let reorder=this.reorder;
		let perPage=('playlist' in this.binds)?-100:100;
		return {url,data,columns,binds,actions,customizers,reorder,perPage};
	}
