'use strict';
import mixin      from '../-mixin/mixin';
import data       from './js/data';
import computed   from './js/computed';
import methods    from './js/methods';
import updated    from './js/updated';
import components from './js/components';

let name=window.location.path;

export default (
	{
		mixins:[mixin],
		name,
		data,
		computed,
		methods,
		updated,
		components,
	})
;
