'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function pushTracks (method)
	{
		let tracks=this.filteredTracks;
		wrapper[method] (`mood_factory/${this.factories[this.factoryTab].mood_id}/track`,{tracks}).success (success.bind (this));

		function success ()
			{
				this.tableReload=Math.random ();
				this.loadFactories ();
			}
	}
