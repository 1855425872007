'use strict';
import {isPlainObject} from 'lodash/lang';

export default function loadSuccess (response)
	{
		if (!isPlainObject (response)) response={};
		this.formData=response.data??{};
		let appends=response.appends??{};
		let shows=appends.shows;
		this.shows=shows;
		shows=Object.keys (shows);
		if (shows.length<2) this.formData.show_id=shows[0];
		let program_managers=appends.program_managers;
		this.program_managers=program_managers;
		program_managers=Object.keys (program_managers);
		if (program_managers.length<2) this.formData.program_manager_id=program_managers[0];
	}
