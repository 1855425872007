'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function load ()
	{
		this.preloader=true;
		wrapper.get (`track/${this.track.id}/play`,{responseType:'arraybuffer',signal:this.abortController.signal,}).success (success.bind (this)).error (error.bind (this));

		function success (arrayBuffer)
			{
				this.audioObject.context.decodeAudioData (arrayBuffer,success.bind (this),error.bind (this));

				function success (audioBuffer)
					{
						this.audio.buffer=audioBuffer;
						this.preloader=false;
						this.drawWave ();
						this.$emit ('loaded');
					}
			}

		function error (error)
			{
				console.log (error);
				this.preloader=false;
			}
	}
