'use strict';
export default function table ()
	{
		let url=`show/table`;
		let data=['#','cover','title','jingle','playlist_count','draft_count','ready_count','submitted_count','scheduled_count'];
		let columns=
			{
				'#':'#',
				cover:'',
				title:{title:'Show title',order:0},
				jingle:{title:'Jingle',order:0},
				playlist_count:{title:'Playlists',clickable:true},
				draft_count:{title:'Draft',clickable:true},
				ready_count:{title:'Ready',clickable:true},
				submitted_count:{title:'Submitted',clickable:true},
				scheduled_count:{title:'Scheduled',clickable:true},

			};
		if (USER.role==='Program manager') delete (columns.id);
		let controls={create:'Create'};
		let actions={};
		if (USER.role==='Admin') actions=Object.assign (actions,
			{
				//view:{title:'Open',icon:`\uf06e`},
				edit:{title:'Edit',icon:`\uf044`},
				delete:{title:'Delete',icon:`\uf2ed`},
			});
		let hideFreeSearch=USER.role==='Program manager';
		let perPage=USER.role==='Program manager'?-100:100;
		return ({url,data,perPage,hideFreeSearch,controls,columns,actions});
	}
