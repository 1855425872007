'use strict';
import {VueFormCheck}      from 'bomber-net-vue-forms/elements';
import FilterText          from './components/FilterText/FilterText.vue';
import FilterSelect        from './components/FilterSelect/FilterSelect.vue';
import FilterDropdown      from './components/FilterDropdown/FilterDropdown.vue';
import FilterRange         from './components/FilterRange/FilterRange.vue';
import FilterDaterange     from './components/FilterRange/FilterRange.vue';
import FilterDatetimerange from './components/FilterRange/FilterRange.vue';
import FilterTabs          from './components/FilterTabs/FilterTabs.vue';
import FilterTags          from './components/FilterTags/FilterTags.vue';

export default (
	{
		VueFormCheck,
		FilterText,
		FilterSelect,
		FilterDropdown,
		FilterRange,
		FilterDaterange,
		FilterDatetimerange,
		FilterTabs,
		FilterTags,
	});
