'use strict';
export default (
	{
		deep:true,
		handler (shows)
			{
				let button=document.querySelector('.playlist-popup-reorder .vue-popup-edit-button-save');
				button.disabled=false;
			}
	});
