'use strict';
import mixin from '../-mixin/mixin';
import computed from './js/computed';
import components from './js/components';

export default (
	{
		mixins:[mixin],
		name:'NewsCategories',
		computed,
		components,
	});
