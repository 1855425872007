'use strict';
import load             from './methods/load';
import loadRoutes       from './methods/loadRoutes';
import loadChapters     from './methods/loadChapters';
import setOriginRoute   from './methods/setOriginRoute';
import setActiveChapter from './methods/setActtveChapter';
import checkIfFreshUser from './methods/checkIfFreshUser';
import refreshArchive   from './methods/refreshArchive';

export default (
	{
		load,
		loadRoutes,
		loadChapters,
		setOriginRoute,
		setActiveChapter,
		checkIfFreshUser,
		refreshArchive
	});
