'use strict';
import wrapper        from 'bomber-net-axios-wrapper';
import {isObjectLike} from 'lodash/lang';
import {startsWith}   from 'lodash/string';
import components     from '../../../../../../chapters/crm/chapters';

export default function loadRoutes ()
	{
		return new Promise ((resolve,reject)=>
			{
				wrapper.get ('routes').success (routes=>
					{
						try
							{
								let index={};
								if (!isObjectLike (routes)) return reject ('Invalid routes structure');
								if (Array.isArray (routes))
									{
										routes.forEach (route=>
											{
												if (!('path' in route)) return reject ('Invalid routes structure');
												if (!startsWith (route.path,'/')) route.path=`/${route.path}`;
												route.component=components[route.component];
												route.props=true;
												Router.addRoute (route);
												index[route.id]=route.path;
											});
									} else Router.addRoute (routes);
								resolve (index);
							} catch (e)
								{
									console.log (e);
									reject ('Error loading routes');
								}
					}).error (error=>
						{
							console.log (error);
							reject ('Error loading routes');
						});
			});
	}
