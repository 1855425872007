'use strict';
export default (
	{
		deep:true,
		handler (shows)
			{
				shows=Object.keys (shows);
				if (shows.length<2) this.formData.show_id=shows[0]??null;
				if (!shows.includes (`${this.formData.show_id}`)) this.formData.show_id=null;
			}
	});
