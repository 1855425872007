'use strict';
import popupBind  from './computed/popupBind';
import tracks     from './computed/tracks';
import selected   from './computed/selected';
import allChecked from './computed/allChecked';

export default (
	{
		popupBind,
		tracks,
		selected,
		allChecked,
	});
