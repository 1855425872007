'use strict';
import props         from './js/props';
import data          from './js/data';
import computed      from './js/computed';
import watch         from './js/watch';
import methods       from './js/methods';
import mounted       from './js/mounted';
import beforeUnmount from './js/beforeUnmount';
import components    from './js/components';

export default (
	{
		name:'MixerTrack',
		props,
		data,
		computed,
		watch,
		methods,
		mounted,
		beforeUnmount,
		components,
	});
