<VuePopupEdit v-bind="popupBind" v-on="popupEvents" class="playlist-popup-reorder" @mouseup.left.exact.stop.prevent="dragend">
	<div class="playlist-popup-reorder-buttons">
		<div class="playlist-popup-reorder-buttons-checks">
			<button type="button" @click="checkAll ()" class="playlist-popup-reorder-button-check" title="Check all">
				<VueIcon :icon="`\uf560`"/>
			</button>
			<button type="button" @click="checked={}" class="playlist-popup-reorder-button-check" title="Uncheck all">
				<VueIcon :icon="`\uf0c9`"/>
			</button>
			<button type="button" @click="checkToggle" class="playlist-popup-reorder-button-check" title="Toggle check">
				<VueIcon :icon="`\uf362`"/>
			</button>
		</div>
		<button type="button" @click="playlistShuffle" class="playlist-popup-reorder-button">
			<VueIcon :icon="`\uf074`">Shuffle playlist</VueIcon>
		</button>
		<button type="button" @click="playlistReverse" class="playlist-popup-reorder-button">
			<VueIcon :icon="`\uf079`">Reverse playlist</VueIcon>
		</button>
		<button type="button" :disabled="!selected.length || allChecked" @click="selectedToStart" class="playlist-popup-reorder-button">
			<VueIcon :icon="`\uf148`">Selected to start</VueIcon>
		</button>
		<button type="button" :disabled="!selected.length || allChecked" @click="selectedToEnd" class="playlist-popup-reorder-button">
			<VueIcon :icon="`\uf149`">Selected to end</VueIcon>
		</button>
	</div>
	<div :class="{'playlist-popup-reorder-tracks-drag':dragEmitter}" class="playlist-popup-reorder-tracks" @mouseup.left.exact.stop.prevent="dragend">
		<div v-for="track in tracks" :class="{'playlist-popup-reorder-track-selected':checked[track.id]}" class="playlist-popup-reorder-track" @mouseleave="dragmove ($event,track.id)">
			<VueIcon :icon="`\uf0c9`" class="playlist-popup-reorder-track-mover" @mousedown.left.exact.stop.prevent="dragstart (track.id)"/>
			<VueIcon :icon="`\uf00c`" @click="checked[track.id]=!checked[track.id]" class="playlist-popup-reorder-track-checkbox"/>
			<span v-text="track.caption" class="playlist-popup-reorder-track-caption"/>
		</div>
	</div>
</VuePopupEdit>
