'use strict';
import {isPlainObject} from 'lodash/lang';

export default function loadSuccess ({data,appends})
	{
		if (!isPlainObject (data)) data={global_visible:false};
		if (!isPlainObject (appends)) appends={};
		let roles=appends.roles ?? {};
		this.roles=roles;
		roles=Object.keys (roles);
		if (roles.length<2) data.role=roles[0];
		switch (data.role)
			{
				case 'Program manager':
					if (!data.links) data.links=[];
					break;
				case 'Curator':
					let program_managers=appends.program_managers ?? {};
					this.program_managers=program_managers;
					program_managers=Object.keys (program_managers);
					if (program_managers.length<2) data.program_manager_id=parseInt (program_managers[0]);
					this.shows=appends.shows ?? {};
					break;
			}
		this.formData=data;
	}
