'use strict';
export default function data ()
	{
		return (
			{
				formData:{},
				errors:{},
				popupEvents:
					{
						loadSuccess:this.loadSuccess,
						submitError:this.submitError,
					},
			});
	}
