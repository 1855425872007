<div :style="style">
	<div class="mixer-track-caption" :title="track.title">
		<div v-text="track.title" class="mixer-track-title"/>
		<div v-text="`(${duration})`"/>
	</div>
	<div class="mixer-track-wave">
		<canvas v-bind="canvasBind" ref="canvas"/>
	</div>
	<div class="mixer-track-info">
		<div v-html="`BPM ${Math.round(track.bpm)}`"/>
		<div v-if="track.key" v-html="`- KEY ${track.key?.value}`"/>
	</div>
	<VueHelperPreloader v-if="preloader"/>
</div>
