'use strict';
export default function inputType ()
	{
		switch (this.type)
			{
				case 'Daterange':
					return 'date';
				case 'Datetimerange':
					return 'datetime';
				default:
					return 'text';
			}
	}
