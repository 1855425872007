<div class="vue-table-container">
	<div class="vue-table-header">
		<VueFormPlusMinus v-if="perPage>0" v-model="pages.perPage" :clamp="{min:1}">
			Per page
		</VueFormPlusMinus>
		<TableFilters v-if="hideFreeSearch" v-model="filter" v-model:inverted="invertedFilter" :filters="filters">
			<slot/>
		</TableFilters>
		<TableFilters v-else v-model="filter" v-model:inverted="invertedFilter" :filters="filters" v-model:freeSearch="freeSearch" :class="{'free-search-preloader':freeSearchPreloader}">
			<slot/>
		</TableFilters>
		<div class="vue-table-total" v-text="`Total: ${counts.total}`"/>
		<div class="vue-table-header-buttons">
			<TableControls :controls="allowedControls" @control="controlClick" class="vue-table-controls"/>
			<button title="Reload" type="button" @click="load (true)" class="vue-table-reload"/>
		</div>
	</div>
	<div class="vue-table-header-second-row">
		<slot name="headerSecondRow"/>
	</div>
	<div v-if="error" v-text="error"/>
	<TableBody v-else v-model:order="order" v-bind="{data,columns,checkboxes,checked,actions,rows,customizers,reorder,unsorted}" @action="actionClick" @cell="cellClick"
		@checked="checked=$event" @reorder="reorderMethod">
		<template v-for="(slot,name) in bodySlots" v-slot[slot]="{row}">
			<slot :name="name" v-bind="row"/>
		</template>
	</TableBody>
	<TablePaginator v-model="pages.current" :total="pages.total"/>
	<VueHelperPreloader v-if="!noPreloader && preloader"/>
</div>
