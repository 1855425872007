<VuePopupEdit v-bind="popupBind" v-on="popupEvents" class="playlist-popup-edit">
	<template v-if="!formData.id">
		<VueFormInput v-model="formData.title" v-model:e="errors.title" placeholder="Please write the title of your show">Title</VueFormInput>
		<VueFormSelect v-if="Object.keys (program_managers).length>1" v-model="formData.program_manager_id" v-model:e="errors.proggram_manager_id" :options="program_managers" hint="Choose Program Manager">
			Program manager
		</VueFormSelect>
		<div v-else-if="Object.keys (program_managers).length" v-text="`Program manager: ${program_managers[formData.program_manager_id]}`"/>
		<VueFormSelect v-if="Object.keys (filtered_shows).length>1" v-model="formData.show_id" v-model:e="errors.show_id" :options="filtered_shows">Show</VueFormSelect>
		<div v-else-if="Object.keys (filtered_shows).length" v-text="`Show: ${filtered_shows[formData.show_id]}`"/>
	</template>
	<template v-else>
		<VueFormInput v-model="formData.title" v-model:e="errors.title" placeholder="Please write the title of your show">Title</VueFormInput>
		<div v-text="`Show: ${shows[formData.show_id]}`" class="vue-popup-view-item"/>
		<div v-text="`Program manager: ${program_managers[formData.program_manager_id]}`" class="vue-popup-view-item"/>
	</template>
	<VueFormInput v-model="formData.comment" v-model:e="errors.comment" type="area" placeholder="Write your comments">Comment</VueFormInput>
	<VueFormInput v-if="USER.role==='Admin'" v-model="formData.soundcloud_secret" v-model:e="errors.soundcloud_secret" type="area" placeholder="Soundcloud Secret">Soundcloud Secret</VueFormInput>
</VuePopupEdit>
