'use strict';
export default function data ()
	{
		return (
			{
				program_managers:{},
				jingles:{},
				checked:[],
				programManagers:false,
				covers:
					{
						// cover:{previewSize:1000,size:'500x500',title:'WEB - archives catalog'},
						// cover_app_radio:{previewSize:1000,size:'500x500',title:'app (Radio)'},
						cover:{previewSize:1000,size:'500x500',title:'app (Radio)'},
						cover_app_radio:{previewSize:1000,size:'500x500',title:'WEB - archives catalog'},
						cover_app_archives:{previewSize:1000,size:'369x261',title:'app (archives)'},
						cover_app_schedule_l:{previewSize:1000,size:'407x216',title:'app (schedule) L'},
						cover_app_schedule_s:{previewSize:500,size:'171x172',title:'app (schedule) S'},
						cover_dashboard:{previewSize:500,size:'250x150',title:'dashboard'},
						share_cover:{previewSize:500,size:'1000x1000',title:'share cover'},
						cover_desktop:{previewSize:500,size:'500x500',title:'cover desktop'},
						cover_mobile:{previewSize:500,size:'1080x1920',title:'cover mobile'},
					}
			});
	}
