'use strict';
import titles      from './computed/titles';
import clickables  from './computed/clickables';
import checkedRows from './computed/checkedRows';

export default (
	{
		titles,
		clickables,
		checkedRows,
	});
