'use strict';
export default function popupBind ()
	{
		let path=this.path;
		let user=this.object;
		let src=user?`${path}/${user.id}/edit`:`${path}/create`;
		let dst=user?`${path}/${user.id}`:path;
		let formData=this.formData;
		let method=user?'put':'post';
		return {src,dst,formData,method};
	}
