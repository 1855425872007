'use strict';
export default function data ()
	{
		return (
			{
				formData:[],
				checked:{},
				dragEmitter:null,
			});
	}
