'use strict';
import {isString} from 'lodash/lang';
import {omit}     from 'lodash/object';

export default function bind ()
	{
		let filter=isString (this.filter)?{}:omit (this.filter,['type','title']);
		let type=this.type;
		let is=`filter-${type}`;
		let title=this.title;
		let modelValue=this.empty?null:this.modelValue;
		return Object.assign (filter,{is,title,type,modelValue});
	}
