'use strict';
import loadSuccess     from './methods/loadSuccess';
import checkAll        from './methods/checkAll';
import checkToggle     from './methods/checkToggle';
import playlistShuffle from './methods/playlistShuffle';
import playlistReverse from './methods/playlistReverse';
import selectedToStart from './methods/selectedToStart';
import selectedToEnd   from './methods/selectedToEnd';
import disableSave     from './methods/disableSave';
import dragstart       from './methods/dragstart';
import dragmove        from './methods/dragmove';
import dragend         from './methods/dragend';

export default (
	{
		loadSuccess,
		checkAll,
		checkToggle,
		playlistShuffle,
		playlistReverse,
		selectedToStart,
		selectedToEnd,
		disableSave,
		dragstart,
		dragmove,
		dragend,
	});
