'use strict';
export default function popupBind ()
	{
		let genre=this.object;
		let src=genre?`genre/${genre.id}/edit`:'genre/create';
		let dst=genre?`genre/${genre.id}`:'genre';
		let formData=this.formData;
		let method=genre?'put':'post';
		return {src,dst,formData,method};
	}
