<VuePopupEdit v-bind="popupBind" v-on="popupEvents" class="show-popup-edit">
	<VueFormInput v-model="formData.title" v-model:e="errors.title">Title</VueFormInput>
	<VueFormSelect :options="schedule_frequency" v-model="formData.schedule_frequency" v-model:e="errors.schedule_frequency">
		Schedule frequency
	</VueFormSelect>
	<VueFormInput v-model="formData.description" v-model:e="errors.description" type="area">Description</VueFormInput>
	<VueFormSelect showOne canReset :options="jingles" v-model="formData.jingle_id" v-model:e="errors.jingle_id">
		Jingle
	</VueFormSelect>
	<div class="popup-photos-group">
		<VueFormFramedImage v-for="(cover,name) in covers" v-model="formData[name]" v-bind="cover" padding="20" accept="png,jpg,gif" class="popup-cover">
			<template #file>
				<VueIcon :icon="`\uf0ee`"/>
				Select cover
			</template>
			<div class="framedimage-hint">
				<div v-text="cover.title"/>
				<div v-text="cover.size"/>
			</div>
		</VueFormFramedImage>
	</div>
	<div class="show-popup-edit-program-managers-container">
		<div class="program-managers-select" @click="programManagers=!programManagers">Program managers
			<VueIcon v-if="programManagers" :icon="`\uf077`"/>
			<VueIcon v-else :icon="`\uf078`"/>
		</div>
		<div class="program-managers-options-wrapper" v-if="programManagers">
			<VueFormCheck v-for="(program_manager,id) in program_managers" v-model="checked[id]">{{ program_manager }}
			</VueFormCheck>
		</div>
	</div>
</VuePopupEdit>
