'use strict';
import allowAdd      from './computed/allowAdd';
import allowReedit   from './computed/allowReedit';
import allowMix      from './computed/allowMix';
import inMixers      from './computed/inMixers';
import allowSubmit   from './computed/allowSubmit';
import allowDecline  from './computed/allowDecline';
import mixSrc        from './computed/mixSrc';
import viewSelectMix from './computed/viewSelectMix';

export default (
	{
		allowAdd,
		allowReedit,
		allowMix,
		inMixers,
		allowSubmit,
		allowDecline,
		mixSrc,
		viewSelectMix,
	});
