'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function loadFactories ()
	{
		return new Promise (loader.bind (this));

		function loader (resolve,reject)
			{
				wrapper.get ('mood_factory').success (success.bind (this)).error (error.bind (this));

				function success (response)
					{
						this.factories=response;
						this.tableReload=Math.random ();
						resolve ();
					}

				function error (error)
					{
						reject (error);
					}
			}
	}
