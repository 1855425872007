'use strict';
export default function tracks ()
	{
		if (!this.formData.tracks) return {};
		return this.formData.tracks.reduce ((tracks,track)=>
			{
				tracks[track.order]=Object.assign (track,{caption:[track.artist,track.title].filter (title=>title).join (' - ')});
				return tracks;
			},{});
	}
