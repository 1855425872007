'use strict';
import {isPlainObject} from 'lodash/lang';
import {uniq}          from 'lodash/array';
import {pickBy}        from 'lodash/object';

export default function tableDefault ()
	{
		let defaultTable=Object.assign ({binds:this.binds,reload:this.tableReload},this.$attrs);
		let table=this.table;
		let mixin=this.tableMixin;
		mix (defaultTable,table);
		mix (defaultTable,mixin);
		return defaultTable;

		function mix (dst,src)
			{
				for (let i in src)
					{
						let s=src[i];
						switch (true)
							{
								case isPlainObject (s):
									dst[i]=pickBy (Object.assign (dst[i] ?? {},s),e=>e!==null);
									break;
								case Array.isArray (s):
									dst[i]??=[];
									s.forEach (e=>dst[i].push (e));
									dst[i]=uniq (dst[i]).filter (e=>e!==null);
									break;
								default:
									dst[i]=s;
							}
					}
			}
	}
