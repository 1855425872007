'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function loadMoods ()
	{
		return new Promise (loader.bind (this));

		function loader (resolve,reject)
			{
				wrapper.get ('mood').success (success.bind (this)).error (error.bind (this));

				function success (response)
					{
						this.moods=response.reduce ((moods,mood)=>
							{
								moods[mood.id]=mood.title;
								return moods;
							},{});
						this.tableReload=Math.random ();
						resolve ();
					}

				function error (error)
					{
						reject (error);
					}
			}
	}
