'use strict';
import popupClose            from './methods/popupClose';
import reload                from './methods/reload';
import fullReload            from './methods/fullReload';
import reloadTable           from './methods/reloadTable';
import reorder               from './methods/reorder';
import reorderPlaylist       from './methods/reorderPlaylist';
import buttonBind            from './methods/buttonBind';
import downloadButtonBind    from './methods/downloadButtonBind';
import totalDownloadProgress from './methods/totalDownloadProgress';
import mixProgress           from './methods/mixProgress';
import hasMix                from './methods/hasMix';
import mixFullfilled         from './methods/mixFullfilled';
import mixAction             from './methods/mixAction';
import downloadMix           from './methods/downloadMix';
import deleteMixes           from './methods/deleteMixes';
import submit                from './methods/submit';
import subscribe             from './methods/subscribe';
import mixerprogress         from './methods/mixerprogress';
import unsubscribe           from './methods/unsubscribe';
import editMix               from './methods/editMix';
import decline               from './methods/decline';
import setMix                from './methods/setMix';
import tracksUploaded        from './methods/tracksUploaded';
import allSubmit             from './methods/allSubmit';
import stopPlayMix           from './methods/stopPlayMix';

export default (
	{
		popupClose,
		reload,
		fullReload,
		reloadTable,
		reorder,
		reorderPlaylist,
		buttonBind,
		downloadButtonBind,
		totalDownloadProgress,
		mixProgress,
		hasMix,
		mixFullfilled,
		mixAction,
		downloadMix,
		deleteMixes,
		submit,
		subscribe,
		mixerprogress,
		unsubscribe,
		editMix,
		decline,
		setMix,
		tracksUploaded,
		allSubmit,
		stopPlayMix,
	});
