'use strict';
export default function table ()
	{
		let data=['#','title','artist','bpm','energy','key','duration','moods','tags'];
		let binds,order,filters,controls,actions,bindActions,reorder;
		controls={};
		actions={delete:null,edit:null,download:null};
		bindActions={};
		if (this.isEditMode)
			{
				binds={mood_factory:this.factoryTab};
				filters={};
				reorder=true;
				controls.shuffle={title:'Shuffle',icon:`\uf074`};
				controls.excel={title:'Download track list to Excel',icon:`\uf1c3`};
				controls.unbindAll={title:'Delete all from moodfactory',icon:`\uf127`};
				actions.unbindMoodFactory={title:'Delete from moodfactory',icon:`\uf127`};
				bindActions.mood_factory=this.factoryTab;
			} else
			{
				binds={};
				filters=
					{
						mood:{title:'Mood',type:'tags',options:this.moods},
						moodExclude:{title:'Exclude mood',type:'tags',options:this.moods},
						tag:{title:'Tag',type:'tags',options:this.tags},
						tagExclude:{title:'Exclude tag',type:'tags',options:this.tags},
						bpm:{title:'BPM',type:'range'},
						energy:{title:'Energy',type:'range'},
					};
				reorder=false;
			}
		let perPage=-100;
		let reload=this.tableReload;
		return {binds,data,filters,controls,actions,bindActions,perPage,reload,unsorted:true,reorder};
	}
