'use strict';

import props      from './js/props';
import data       from './js/data';
import computed   from './js/computed';
import methods    from './js/methods';
import components from './js/components';

export default (
	{
		name:'Body',
		props,
		data,
		computed,
		methods,
		components,
	});
