'use strict';
import CRMLayout           from '../../../../../components/layouts/CRMLayout/CRMLayout.vue';
import PopupVideo          from '../../../../../popups/main/PopupVideo/PopupVideo.vue';
import PopupWelcome        from '../../../../../popups/main/PopupWelcome/PopupWelcome.vue';
import PopupArchiveConfirm from '../../../../../popups/main/PopupArchiveConfirm/PopupArchiveConfirm.vue';
import CRMMenu             from './components/CRMMenu/CRMMenu.vue';
import CRMChat             from './components/CRMChat/CRMChat.vue';
import CRMUser             from './components/CRMUser/CRMUser.vue';
import CRMNotifications    from './components/CRMNotifications/CRMNotifications.vue';

export default (
	{
		CRMLayout,
		CRMMenu,
		CRMChat,
		PopupVideo,
		PopupWelcome,
		PopupArchiveConfirm,
		CRMUser,
		CRMNotifications,
	});
