<VuePopupView @close="close" @submit="close" class="welcome-popup" style="padding: 0;border-radius: 20px;border: none;width: 930px;margin: auto; height: auto;">
	<div class="welcome-popup-wrapper">
		<div class="welcome-popup-top">
			<h1>WELCOME</h1>
			<p>This is the official ROVR music dashboard where you can create and submit your playlist.</p>
		</div>
		<div class="welcome-popup-bottom">
			<div class="welcome-popup-list">
				<ul>
					<li>
						<img src="./images/welcome-check.png" alt="">
						UPLOAD & ARRANGE YOUR TRACKS.
					</li>
					<li>
						<img src="./images/welcome-check.png" alt="">
						MODIFY & REVIEW YOUR PLAYLIST.
					</li>
					<li>
						<img src="./images/welcome-check.png" alt="">
						SUBMIT OR SAVE YOUR CHANGES FOR LATER.
					</li>
				</ul>
			</div>
			<strong>Ready?</strong>
			<p>Click the “Add” button located on the top right of the page to start.</p>
			<div class="welcome-popup-confirm-wrapper">
				<div class="welcome-popup-confirm" @click="welcomeCheck=!welcomeCheck">
					<div class="welcome-popup-confirm-check"><VueIcon v-if="welcomeCheck" :icon="`\uf00c`"/></div>
					I Confirm
				</div>
				<button :disabled="!welcomeCheck" type="submit" class="welcome-popup-close">Close</button>
			</div>
		</div>

		<img src="./images/rovr-r.svg" alt="" class="rovr-welcome-letters letter-r-left">
		<img src="./images/rovr-o.svg" alt="" class="rovr-welcome-letters letter-o">
		<img src="./images/rovr-v.svg" alt="" class="rovr-welcome-letters letter-v">
		<img src="./images/rovr-r-black.svg" alt="" class="rovr-welcome-letters letter-r-right">
	</div>

</VuePopupView>
