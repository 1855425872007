'use strict';
import props    from './js/props';
import data     from './js/data';
import computed from './js/computed';

export default (
	{
		name:'MenuItem',
		props,
		data,
		computed,
	});
