'use strict';
export default function orderColumn (column)
	{
		if (this.unsorted) return null;
		switch (this.order[column])
			{
				case -1:
					return 'desc';
				case 0:
					return 'unsorted';
				case 1:
					return 'asc';
				default:
					return null;
			}
	}
