'use strict';
export default function editMix ()
	{
		this.lockedReedit=true;
		this.stopPlayMix ().then (()=>
			{
				this.lockedReedit=false;
				this.popup='Reedit';
			});
	}
