<div>
	<div class="mixer-zoom">
        <div class="mixer-zoom-btn mixer-zoom-minus">-</div>
        <img src="./images/search.png" alt="">
        <div class="mixer-zoom-btn mixer-zoom-plus">+</div>
    </div>
    
	<button class="mixer-tool"><img src="./images/cut.png" alt=""></button>
    <button class="mixer-tool"><img src="./images/magnit-left.png" alt=""></button>
    <button class="mixer-tool"><img src="./images/magnit-right.png" alt=""></button>
    <button class="mixer-tool"><img src="./images/undo.png" alt=""></button>
    <button class="mixer-tool"><img src="./images/undo-right.png" alt=""></button>
</div>
