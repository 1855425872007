'use strict';
export default function dragend (event)
	{
		if (this.isDragging)
			{
				this.isDragging=false;
				window.removeEventListener ('mousemove',this.dragmove);
				window.removeEventListener ('mouseleave',this.dragend);
				window.removeEventListener ('mouseup',this.dragend);
				let from=this.from;
				let to=this.to;
				let down=this.downDrag;
				this.from=null;
				this.to=null;
				let target=event.target;
				if (!(target && target.closest && target.closest ('tbody'))) return;
				if (from!==to)
					{
						to=to-(to>from)+down;
						if (from!==to) this.$emit ('reorder',{from,to});
					}
			}
	}
