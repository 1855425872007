'use strict';
export default function popupBind ()
	{
		let id=this.object?.id;
		let src=id?`tag/${id}/edit`:'tag/create';
		let dst=id?`tag/${id}`:'tag';
		let formData=this.formData;
		let method=id?'put':'post';
		return {src,dst,formData,method};
	}
