'use strict';
import io   from 'socket.io-client';
import Echo from 'laravel-echo';

export default function socketConnect ()
	{
		window.io=io;
		window.Echo=new Echo (
			{
				broadcaster:'socket.io',
				namespace:'App.Events',
				host:window.location.host,
			});
	}
