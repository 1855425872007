'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function load ()
	{
		wrapper.get (`mood_factory/${this.factory.mood_id}`).success (success.bind (this)).error (error.bind (this));

		function success ({data,appends})
			{
				this.formData=data??{};
				this.factories=appends?.factories??{};
				this.$nextTick (nextTick.bind (this));

				function nextTick ()
					{
						this.modified=false;
						this.$emit ('loaded',data);
					}
			}

		function error (error)
			{
				console.log (error);
			}
	}
