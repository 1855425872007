'use strict';
import load          from './methods/load';
import controlClick  from './methods/controlClick';
import actionClick   from './methods/actionClick';
import cellClick     from './methods/cellClick';
import reorderMethod from './methods/reorderMethod';

export default (
	{
		load,
		controlClick,
		actionClick,
		cellClick,
		reorderMethod,
	});
