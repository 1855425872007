'use strict';
export default function table ()
	{
		let path=this.path;
		let url=`${path}/table`;
		let data=['#','id','name','email'];
		let controls={create:'Create'};
		switch (path)
			{
				case 'user':
					data.push ('role');
					delete (controls.create);
					break;
				case 'program_manager':
					data.push ('shows','title_for_website','about','photo','small_photo');
					break;
				case 'curator':
					if (USER.role==='Admin') data.push ('program_managers','shows',);
					data.push ('status');
					break;
			}
		let columns=
			{
				'#':{title:'#'},
				id:{title:'ID',order:0},
				name:{title:'Name',order:0},
				email:{title:'email',order:0},
				role:{title:'Role',order:0},
				title_for_website:{title:'Title for website'},
				about:{title:'About'},
				photo:{title:'Photo'},
				small_photo:{title:'Small photo'},
				program_managers:{title:'Program managers'},
				shows:{title:'Shows'},
				status:{title:'Status'},
			};
		let actions=
			{
				edit:{title:'Edit',icon:`\uf044`},
				password:{title:'Change password',icon:`\uf084`},
			};
		return ({url,data,columns,controls,actions});
	}
