'use strict';
import axios from "axios";

export default function upload ()
	{
		let file=this.file;
		let data=new FormData ();
		data.append ('track',file,file.name);
		axios.post (this.dst,data,{onUploadProgress:e=>this.current=e.loaded})
			.then (()=>this.$emit ('success',this.id)).catch (()=>this.$emit ('error'));
	}
