'use strict';
export default function data ()
	{
		return (
			{
				showPopup:true,
				userObject:undefined,
				error:null,
			});
	}
