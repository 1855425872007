'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default async function checkIfFreshUser ()
	{
		if (USER.fresh)
			{
				switch (USER.role)
					{
						case 'Curator':
							this.popup='PopupWelcome';
							break;
						default:
							wrapper.delete (`fresh_user/${USER.id}`);
					}
			}
	}
