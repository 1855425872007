'use strict';
import {VueFormPlusMinus}   from 'bomber-net-vue-forms/elements';
import {VueHelperPreloader} from 'bomber-net-vue-forms/helpers';
import TableFilters         from './components/TableFilters/TableFilters.vue';
import TableControls        from './components/TableControls/TableControls.vue';
import TableBody            from './components/TableBody/TableBody.vue';
import TablePaginator       from './components/TablePaginator/TablePaginator.vue';

export default (
	{
		VueFormPlusMinus,
		TableFilters,
		TableControls,
		TableBody,
		TablePaginator,
		VueHelperPreloader,
	});
