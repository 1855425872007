'use strict';
import bindPlay  from './computed/bindPlay';
import bindPause from './computed/bindPause';
import bindStop  from './computed/bindStop';

export default (
	{
		bindPlay,
		bindPause,
		bindStop,
	});
