<div class="mood-factory-view-container">
	<div>
		<VueIcon v-if="modified" :icon="`\uf0c7`" @click="save"/>
		<div class="mood-factory-view-title">
			<div v-if="!editTitle">
				<span v-text="`Title: ${formData.title}`"/>
				<VueIcon :icon="`\uf044`" @click="editTitle=true"/>
			</div>
			<div v-else class="mood-factory-view-title-save">
				<VueFormInput v-model="formData.title">Title</VueFormInput>
				<VueIcon :icon="`\uf00c`" @click="editTitle=false"/>
			</div>
		</div>
		<div class="view-photo-wrapper">
			<VueFormImage :image="formData.cover" class="popup-view-photo"/>
			<VueIcon v-if="formData.cover" :icon="`\uf2ed`" @click="formData.cover=''" title="Delete cover" class="popup-view-delete"/>
			<VueFormFile @select="selectCover" @click="delete (errors.cover)" accept="image/*" class="popup-select-file">
				<VueIcon :icon="`\uf0ee`"/>
				Select cover
			</VueFormFile>
			<div v-if="errors.cover" v-text="errors.cover[0]??errors.cover" @click="delete (errors.cover)"/>
		</div>
		<template v-if="formData.special">
			<VueFormSelect :options="factories" v-model="formData.substitude_to_mood_factory_id" canReset>
				Substitute to factory
			</VueFormSelect>
			<VueFormCheck v-model="formData.active">Active</VueFormCheck>
		</template>
	</div>
	<div>
		<div v-text="`Duration: ${duration}`"/>
	</div>
</div>
