'use strict';
export default function table ()
	{
		let url=`news/table`;
		let data=['#','id','author_name','picture','category','title','subtitle','text','date'];
		let columns=
			{
				'#':'#',
				id:{title:'ID',order:0},
				picture:'',
				author_name:{title:'Author name'},
				category:{title:'Category',order:0},
				title:{title:'Title',order:0},
				subtitle:{title:'Subtitle',order:0},
				text:{title:'Text',order:0},
				date:{title:'date',order:0},
			};
		let controls=
			{
				create:'Create',
			};
		let actions=
			{
				edit:{title:'Edit',icon:`\uf044`},
				delete:{title:'Delete',icon:`\uf2ed`},
			};
		return ({url,data,controls,columns,actions});
	}
