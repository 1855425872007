'use strict';
export default function created ()
	{
		this.loadFilters ();
		let url=new URL (window.location);
		let query=url.searchParams;
		let id=query.get ('id');
		if (id)
			{
				this.popup='PopupView';
				this.object={id};
				query.delete ('id');
				window.history.pushState (null,document.title,url);
			}
	}
