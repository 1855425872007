'use strict';
export default function checkAll (tracks=null)
	{
		if (!tracks) tracks=this.formData.tracks;
		this.checked=tracks.reduce ((checked,track)=>
			{
				checked[track.id]=true;
				return checked;
			},{});
	}
