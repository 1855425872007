'use strict';
export default function play (offset=0,suspended=false)
	{
		let audio=this.audio;
		let context=audio.context;
		let startedAt=context.currentTime-offset;
		audio.positionInterval=setInterval (()=>audio.position=context.currentTime-audio.startedAt,50);
		audio.startedAt=startedAt;
		context[suspended?'suspend':'resume'] ();
		audio.mode=suspended?'suspended':'running';
	}
