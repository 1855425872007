<VuePopupView :src="`client/${object.id}`" v-on="popupEvents">
	<template v-for="(item,name) in formData">
		<template v-if="typeof item==='object'">
			<div>
				<div class="vue-popup-view-item" v-text="name"/>
				<div class="vue-popup-view-item" v-for="sub in item" v-text="sub.title"/>
			</div>
		</template>
		<div v-else v-text="`${name}:${item}`" class="vue-popup-view-item"/>
	</template>
</VuePopupView>
