'use strict';
export default function data ()
	{
		return (
			{
				isDragging:false,
				dragPosition:{x:0,y:0},
				from:null,
				to:null,
				downDrag:false,
			});
	}
