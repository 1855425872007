'use strict';
import axios from 'axios';

export default function actionDownload (row)
	{
		let link=document.createElement ('a');
		link.href=`${axios.defaults.baseURL}/track/${row.uuid}/download`;
		document.body.appendChild (link);
		link.click ();
	}
