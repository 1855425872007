'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function loadUser ()
	{
		return new Promise ((resolve,reject)=>
			{
				wrapper.get ('user').success (({data})=>
					{
						this.$nextTick (()=>
							{
								this.userObject=data;
								window.USER=data;
							});
						resolve ();
					}).error (({status,data})=>
						{
							if (status!==401) reject ({status,data});
						});
			});
	}
