'use strict';
import {VuePopupView} from 'bomber-net-vue-forms/popups';
import CRMLogin       from './components/CRMLogin/CRMLogin.vue';
import CRMMain        from './components/CRMMain/CRMMain.vue';

export default (
	{
		VuePopupView,
		CRMLogin,
		CRMMain
	});
