'use strict';
import MixerControls from './components/MixerControls/MixerControls.vue';
import MixerTrack    from './components/MixerTrack/MixerTrack.vue';
import MixerSettings from './components/MixerSettings/MixerSettings.vue';
import MixerTools    from './components/MixerTools/MixerTools.vue';

export default (
	{
		MixerControls,
		MixerTrack,
		MixerSettings,
		MixerTools,
	});
