'use strict';
import load        from './methods/load';
import abortLoad   from './methods/abortLoad';
import setupCanvas from './methods/setupCanvas';
import drawWave    from './methods/drawWave';
import play        from './methods/play';
import stop        from './methods/stop';

export default (
	{
		load,
		abortLoad,
		setupCanvas,
		drawWave,
		play,
		stop,
	});
