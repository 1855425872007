<VuePopupHost :src="`playlist/${object.id}`" :noPreloader="true" v-on="popupEvents" @close="popupClose" ref="host" class="playlist-popup-view">
	<div class="playlist-popup-view-header">
		<div>Title: <span v-text="formData.title"/></div>
		<div>ID: <span v-text="formData.id"/></div>
		<div>Show: <span v-text="formData.show"/></div>
		<div>Duration: <span v-text="formData.duration"/></div>
		<div>Tracks count: <span v-text="formData.tracks_count"/></div>
		<div>Status: <span v-text="formData.status"/></div>
	</div>
	<UploadTracks v-if="allowAdd" v-bind="{playlist:object.id}" @submitSuccess="tracksUploaded" @allSubmit="allSubmit"/>
	<div v-if="formData.comment" class="playlist-popup-view-note">Note: <span v-text="formData.comment"/></div>
	<ChapterTracks :binds="{playlist:object.id}" :reorder="formData.canReorder && allFilesSubmitted" :tableMixin="{reload:tableReload}" :noPreloader="true"
				   @load="reload" @reorder="reorder" @reload="reloadTable">
		<div class="playlist-popup-view-inline" :class="{align_end:viewSelectMix}">
			<div class="playlist-popup-view-mix-buttons">
				<audio v-if="mix" :src="mixSrc" :controls="playReady" autoplay ref="audio" @canplay="playReady=true" @play="playMode='play'"
					   @pause="playMode='pause'" @ended="playMode=null" controlsList="nodownload noplaybackrate"/>
				<p v-if="viewSelectMix" class="playlist-popup-view-mix-radio-tip">Select your mix:</p>

				<div v-for="mixType in ['CUT','AUTO','MIXER']" class="playlist-popup-view-inline-button-group" :class="{radio_parent:viewSelectMix}">
					<div v-if="viewSelectMix" class="playlist-popup-view-mix-buttons-aligner">
						<label class="radio-label">
							<input type="radio" name="mix" v-if="hasMix (mixType)" v-model="mixForSubmit" :value="mixType">
							<span/>
						</label>
					</div>
					<button type="button" v-bind="buttonBind (mixType)" @click="mixAction (mixType)" :title="mixType">
						<span v-if="inMixers.includes (mixType)" class="playlist-popup-view-inline-button-download-progress" v-text="mixProgress (mixType)"/>
						<span v-text="mixType.charAt (0)+mixType.slice (1).toLowerCase ()"/>
						<VueIcon :icon="`\uf1ce`" class="playlist-popup-view-inline-button-preloader"/>
					</button>
					<button v-if="hasMix (mixType)" v-bind="downloadButtonBind (mixType)" @click="downloadMix (mixType)" title="DOWNLOAD">
						<VueIcon :icon="`\uf1ce`" class="playlist-popup-view-inline-button-preloader"/>
						<span v-if="![null,undefined].includes (downloadProgress[mixType])" class="playlist-popup-view-inline-button-download-progress" v-text="totalDownloadProgress (mixType)"/>
					</button>
				</div>

				<div v-if="formData.canReorderPlaylist && !formData.submitted_at" class="playlist-popup-view-inline-button-group" :class="{radio_parent:viewSelectMix}">
					<div v-if="viewSelectMix" class="playlist-popup-view-mix-buttons-aligner">
						<label class="radio-label" style="opacity:0;">
							<input type="radio">
							<span/>
						</label>
					</div>
					<!-- <button type="button" :disabled="submitted_at || inMixers.length" class="playlist-popup-view-inline-button" @click="reorderPlaylist" title="Reorder playlist">
						<span>Reorder playlist</span>
					</button> -->
				</div>
				
				<div v-if="formData?.mixes?.length && !formData.submitted_at" class="playlist-popup-view-inline-button-group" :class="{radio_parent:viewSelectMix}">
					<div v-if="viewSelectMix" class="playlist-popup-view-mix-buttons-aligner">
						<label class="radio-label" style="opacity:0;">
							<input type="radio">
							<span/>
						</label>
					</div>
					<button type="button" :disabled="!allowSubmit" class="playlist-popup-view-inline-button" @click="deleteMixes" title="Reset All mixes">
						<span>Reset All mixes</span>
					</button>
				</div>
			</div>
			<div class="playlist-popup-view-mix-buttons">
				<div class="playlist-popup-view-inline-button-group">
					<div v-if="viewSelectMix" class="playlist-popup-view-mix-buttons-aligner"/>
					<button type="button" v-if="allowReedit" :disabled="lockedReedit" @click="editMix" class="playlist-popup-view-inline-button" title="Edit Playlist">
						Edit Playlist
					</button>
				</div>
				<div class="playlist-popup-view-inline-button-group">
					<div v-if="viewSelectMix" class="playlist-popup-view-mix-buttons-aligner"/>
					<button type="button" @click="submit" :disabled="!allowSubmit" class="playlist-popup-view-inline-button" title="Submit">
						Submit
					</button>
				</div>
				<div v-if="allowDecline" class="playlist-popup-view-inline-button-group">
					<div v-if="viewSelectMix" class="playlist-popup-view-mix-buttons-aligner"/>
					<button type="button" @click="decline" :disabled="inMixers.length" class="playlist-popup-view-inline-button" title="Decline">
						Decline
					</button>
				</div>
			</div>
		</div>
	</ChapterTracks>
	<component :is="`Popup${popup}`" v-if="popup" v-bind="{object}" @close="popup=null" @submitSuccess="popup=null;reload ();reloadTable ();"/>
	<VueHelperPreloader v-if="preloader"/>
</VuePopupHost>
