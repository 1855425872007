'use strict';
import url            from './watch/url';
import binds          from './watch/binds';
import data           from './watch/data';
import perPage        from './watch/perPage';
import actions        from './watch/actions';
import bindActions    from './watch/bindActions';
import reload         from './watch/reload';
import filter         from './watch/filter';
import invertedFilter from './watch/invertedFilter';
import order          from './watch/order';
import freeSearch     from './watch/freeSearch';
import pagesPerPage   from './watch/pages/perPage';
import pagesCurrent   from './watch/pages/current';
import checked        from './watch/checked';

export default (
	{
		url,
		binds,
		data,
		perPage,
		actions,
		bindActions,
		reload,

		'pages.perPage':pagesPerPage,
		'pages.current':pagesCurrent,
		filter,
		invertedFilter,
		freeSearch,
		order,

		checked,
	});
