'use strict';
import prev from './computed/prev';
import curr from './computed/curr';
import next from './computed/next';

export default (
	{
		prev,
		curr,
		next,
	});
