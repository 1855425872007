'use strict';
import {VuePopupDelete,VuePopupEdit,VuePopupHost,VuePopupView}                                             from 'bomber-net-vue-forms/popups';
import {VueFormCheck,VueFormFile,VueFormFramedImage,VueFormImage,VueFormInput,VueFormSelect,VueFormSubmit} from 'bomber-net-vue-forms/elements';

export default (
	{
		VuePopupHost,
		VuePopupView,
		VuePopupEdit,
		VuePopupDelete,
		VueFormInput,
		VueFormCheck,
		VueFormSelect,
		VueFormFile,
		VueFormImage,
		VueFormFramedImage,
		VueFormSubmit,
	});
