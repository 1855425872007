'use strict';
export default function popupBind ()
	{
		let id=this.object?.id;
		let src=id?`jingle/${id}/edit`:'jingle/create';
		let dst=id?`jingle/${id}`:'jingle';
		let formData=this.formData;
		let method=id?'put':'post';
		return {src,dst,formData,method};
	}
