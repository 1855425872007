<VueFormInput v-model="formData.title_for_website" v-model:e="errors.title_for_website">Title for website</VueFormInput>
<VueFormInput v-model="formData.about" v-model:e="errors.about" type="area">About</VueFormInput>
<VueFormInput v-model="formData.country_code" v-model:e="errors.country_code" maxlength="9" class="text-uppercase">
	Country code
</VueFormInput>

<div class="popup-photos-group">
	<VueFormFramedImage v-for="(photo,name) in photos" v-model="formData[name]" v-bind="photo" padding="20" accept="png,jpg,gif" class="popup-cover">
		<div class="framedimage-hint">
			<div v-text="photo.title"/>
			<div v-text="photo.size"/>
		</div>
		<template #file>
			<VueIcon :icon="`\uf0ee`"/>
			Select photo
		</template>
	</VueFormFramedImage>
</div>

<div class="program-manager-popup-links">
	Links
	<div v-for="(link,i) in formData.links" class="program-manager-popup-link-row">
		<VueFormInput v-model="formData.links[i]" v-model:e="errors.links[i]"/>
		<button type="button" @click="deleteLink (i)">
			<VueIcon :icon="`\uf2ed`"/>
		</button>
	</div>
	<button type="button" @click="addLink">+</button>
</div>
