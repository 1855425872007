'use strict';
export default function stopPlayMix ()
	{
		return new Promise (stopper.bind (this));

		function stopper (resolve)
			{
				this.mix=null;
				setTimeout (function ()
					{
						this.playMode=null;
						resolve ();
					},100);
			}
	}
