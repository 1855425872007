<VuePopupEdit id="popup-user-edit" v-bind="popupBind" v-on="popupEvents">
	<div class="popup-group">
		<VueFormInput v-model="formData.name" v-model:e="errors.name">Name</VueFormInput>
		<VueFormInput v-if="!formData.id" v-model="formData.email" v-model:e="errors.email" type="email">email
		</VueFormInput>
	</div>

	<div v-if="!formData.id && formData.role!=='Curator'" class="popup-group">
		<VueFormInput v-model="formData.password" v-model:e="errors.password" type="password">Password</VueFormInput>
		<VueFormInput v-model="formData.password_confirmation" v-model:e="errors.password_confirmation" type="password">
			Confirm password
		</VueFormInput>
	</div>

	<VueFormSelect v-if="!formData.id" :options="roles" v-model="formData.role" v-model:e="errors.role">
		Role
	</VueFormSelect>
	<div v-if="(formData.id || Object.keys (roles).length===1) && path==='user'" v-text="`Role: ${roles[formData.role]}`"/>
	<VueFormCheck v-if="USER.role==='Admin'" v-model="formData.global_visible">Visible on site and mobile application</VueFormCheck>
	
	<ProgramManager v-if="formData.role==='Program manager'" v-bind="{formData,errors}"/>
	<Curator v-if="formData.role==='Curator'" v-bind="{formData,errors,program_managers,shows}"/>

</VuePopupEdit>
