'use strict';
import {computed} from 'vue';

export default function ()
	{
		return (
			{
				userObject:computed (()=>this.userObject),
			});
	}
