'use strict';
export default function data ()
	{
		return (
			{
				formData:{},
				errors:{},
				factories:{},
				modified:false,
				editTitle:false,
			});
	}
