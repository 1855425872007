'use strict';
import wrapper from 'bomber-net-axios-wrapper';
import {toPairs} from 'lodash/object';

export default function save ()
	{
		let data=new FormData ();
		for (let pair of toPairs (this.formData)) data.append (pair[0],pair[1]);
		data.append ('_method','PUT');
		wrapper.post (`mood_factory/${this.factory.mood_id}`,data).success (success.bind (this)).error (error.bind (this));

		function success ()
			{
				this.$emit ('updated');
			}

		function error ({data})
			{
				this.errors=data?.errors ?? {};
			}
	}
