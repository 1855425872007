'use strict';
export default function rewind (event)
	{
		if (this.audio.startedAt===null) return;
		let target=event.target;
		let container=target.closest ('#mixer-worktable');
		let rect=container.getBoundingClientRect ();
		let position=container.scrollLeft+event.clientX-rect.left;
		this.seek (position);
	}
