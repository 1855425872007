'use strict';
import wrapper from 'bomber-net-axios-wrapper';
import {trim}  from 'lodash/string';

export default function controlExcel ()
	{
		let url=`mood_factory/${this.tableMixin.binds.mood_factory}/track`;
		wrapper.get (url,{headers:{'x-crm-type':'excel'},responseType:'blob'}).success ((blob,headers)=>
			{
				let url=URL.createObjectURL (blob);
				let link=document.createElement ('a');
				link.href=url;
				link.download=trim (headers['content-disposition'].split ('=')[1],`'"`);
				link.click ();
				URL.revokeObjectURL (url);
			});
	}
