'use strict';
import load          from './methods/load';
import loadMoods     from './methods/loadMoods';
import loadTags      from './methods/loadTags';
import loadFactories from './methods/loadFactories';
import factoryLoaded from './methods/factoryLoaded';
import tableLoaded   from './methods/tableLoaded';
import pushTracks    from './methods/pushTracks';
import reorderTracks from './methods/reorderTracks';
import reloadTable   from './methods/reloadTable';

export default (
	{
		load,
		loadMoods,
		loadTags,
		loadFactories,
		factoryLoaded,
		tableLoaded,
		pushTracks,
		reorderTracks,
		reloadTable,
	});
