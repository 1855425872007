'use strict';
import Dashboard       from './Dashboard/Dashboard.vue';
import Statistics      from './Statistics/Statistics.vue';
import StatisticsV2    from './StatisticsV2/StatisticsV2.vue';
import NewsCategories  from './NewsCategories/NewsCategories.vue';
import News            from './News/News.vue';
import Users           from './Users/Users.vue';
import ProgramManagers from './Users/Users.vue';
import Curators        from './Users/Users.vue';
import Genres          from './Genres/Genres.vue';
import Clients         from './Clients/Clients.vue';
import WaitList        from './WaitList/WaitList.vue';
import MoodFactory     from './MoodFactory/MoodFactory.vue';
import Moods           from './Moods/Moods.vue';
import Tags            from './Tags/Tags.vue';
import Jingles         from './Jingles/Jingles.vue';
import Channels        from './Channels/Channels.vue';
import Calendar        from './Calendar/Calendar.vue';
import Shows           from './Shows/Shows.vue';
import Playlists       from './Playlists/Playlists.vue';
import Tracks          from './Tracks/Tracks.vue';
import Support         from './Support/Support.vue';
import Invite          from './Invite/Invite.vue';
import Mixer           from './Mixer/Mixer.vue';

export default (
	{
		Dashboard,Statistics,StatisticsV2,
		NewsCategories,News,
		Users,ProgramManagers,Curators,
		Genres,Clients,WaitList,
		MoodFactory,
		Moods,Tags,
		Jingles,
		Channels,Calendar,
		Shows,Playlists,Tracks,
		Support,
		Invite,
		Mixer,
	});
