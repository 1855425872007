'use strict';
export default function titles ()
	{
		let columns={};
		for (let i of Object.keys (this.columns))
			{
				let column=this.columns[i];
				columns[i]=typeof (column)==='object'?column.title:column;
			}
		return columns;
	}
