'use strict';
export default function mode (mode)
	{
		switch (mode)
			{
				case 'pause':
					this.$refs.player.pause ();
					break;
				case 'play':
					if (this.$refs.player) this.$refs.player.play ();
					break;
			}
	}
