'use strict';
import controlExcel   from './methods/controlExcel';
import cellCustomizer from './methods/cellCustomizer';
import cell           from "../../Tracks/js/methods/cell";

export default (
	{
		cell,
		controlExcel,
		cellCustomizer,
	});
