'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function reorder ({from,to})
	{
		this.noPreloader=true;
		this.preloader=true;
		this.stopPlayMix ().then (()=>
			{
				this.$nextTick (()=>
					{
						wrapper.post (`playlist/${this.object.id}/reorder/${from.id}/${to.id}`)
							.success (()=>this.tableReload=Date ()).error (error=>{console.log (error);})
							.response (()=>
								{
									this.noPreloader=false;
									this.preloader=false;
								});
					});
			});
	}
