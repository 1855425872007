'use strict';
import mixin   from '../../-mixin/mixin';
import data     from './js/data';
import methods from './js/methods';

export default (
	{
		mixins:[mixin],
		name:'PopupWelcome',
		inheritAttrs:false,
		data,
		methods,
	});
