'use strict';
export default function allowAdd ()
	{
		let status=this.formData.status;
		let allowed=['Draft','Ready for mix','Ready for submit'];
		if (['Admin','Program manager'].includes (USER.role)) allowed.push ('Pending approval');
		switch (USER.role)
			{
				case 'Program manager':
				case 'Admin':
				case 'Curator':
					return allowed.includes (status);
				default:
					return false;
			}
	}
