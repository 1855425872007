'use strict';
import src      from './computed/src';
import total    from './computed/total';
import duration from './computed/duration';
import play     from './computed/play';

export default (
	{
		src,
		total,
		duration,
		play,
	});
