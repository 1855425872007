'use strict';
export default function tableLoaded (data)
	{
		this.filteredTracks=data.filteredKeys;
		if (this.isEditMode)
			{
				this.viewFactoryView=false;
				this.$nextTick (()=>this.viewFactoryView=true);
				if (!this.filteredTracks.length)
					{
						this.isEditMode=false;
						this.load ();
					}
			}
	}
