'use strict';
export default function allowReedit ()
	{
		let status=this.formData.status;
		switch (USER.role)
			{
				case 'Program manager':
				case 'Admin':
					return status==='Submitted';
				case 'Curator':
					return status==='Pending approval';
				default:
					return false;
			}
	}
