'use strict';
export default function table ()
	{
		let url=`support/table`;
		let data=['#','id','author','email','datetime','subject','text'];
		let filters=
			{
				subject:'Subject',
				text:'Text',
			};
		let clickable=true;
		let order=0;
		let columns=
			{
				'#':{title:'#',clickable},
				id:{title:'ID',clickable,order},
				author:{title:'Author',clickable},
				email:{title:'Email',clickable},
				datetime:{title:'Datetime',clickable},
				subject:{title:'Subject',clickable,order},
				text:{title:'Text',clickable,order},
			};
		let actions=
			{
				view:{title:'View',icon:`\uf06e`},
			};
		let customizers={cell:this.cellCustomizer};
		return ({url,data,filters,columns,actions,customizers});
	}
