<VuePopupEdit v-bind="popupBind" v-on="popupEvents">
	<VueFormSelect v-if="Object.keys (shows).length>1" v-model="formData.show_id" v-model:e="errors.show_id" :options="shows" :optionsOrderer="selectOrderer">
		Show
	</VueFormSelect>
	<div v-else-if="Object.keys (shows).length" v-text="`Show: ${shows[formData.show_id]}`"/>
	<VueFormSelect v-if="Object.keys (playlists).length>1" v-model="formData.playlist_id" v-model:e="errors.playlist_id" :options="playlists">
		Playlist
	</VueFormSelect>
	<div v-else-if="Object.keys (playlists).length" v-text="`Playlist: ${playlists[formData.playlist_id]}`"/>
	<div class="schedule-popup-edit-start-end">
		<VueFormInput v-model="formData.day" v-model:e="errors.day" type="date">Day</VueFormInput>
		<VueFormInput v-model="formData.start" v-model:e="errors.start" type="time">Start</VueFormInput>
		<VueFormInput v-model="end" type="time" readonly disabled>End</VueFormInput>
	</div>
</VuePopupEdit>
