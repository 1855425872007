'use strict';
import {isNumber} from 'lodash/lang';

export default function mixProgress (mixType)
	{
		let mixers=this.formData.in_mixers??[];
		let mixer=mixers.find (mixer=>mixer.mixtype===mixType);
		let stage=mixer?.stage.total_stage;
		if (!isNumber (stage)) stage=0;
		return `${(stage*100).toFixed (1)}%`;
	}
