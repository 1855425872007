'use strict';
export default function popupBind ()
	{
		let id=this.object?.id;
		let src=id?`mood/${id}/edit`:'mood/create';
		let dst=id?`mood/${id}`:'mood';
		let formData=this.formData;
		let method=id?'put':'post';
		return {src,dst,formData,method};
	}
