'use strict';
import loadFilters            from './methods/loadFilters';
import cell                   from './methods/cell';
import actionTracklist        from './methods/actionTracklist';
import actionReorder          from './methods/actionReorder';
import actionAllowForSchedule from './methods/actionAllowForSchedule';
import optionsOrderer         from './methods/optionsOrderer';
import actionMetadata         from './methods/actionMetadata';
import cellCustomizer         from './methods/cellCustomizer';

export default (
	{
		loadFilters,
		cell,
		actionTracklist,
		actionReorder,
		actionAllowForSchedule,
		optionsOrderer,
		actionMetadata,
		cellCustomizer,
	});
