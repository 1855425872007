'use strict';
import switchOrder        from './methods/switchOrder';
import orderColumn        from './methods/orderColumn';
import rowClass           from './methods/rowClass';
import cellClass          from './methods/cellClass';
import cellClick          from './methods/cellClick';
import allowedActions     from './methods/allowedActions';
import toggleChecked      from './methods/toggleChecked';
import dragstart          from './methods/dragstart';
import dragmove           from './methods/dragmove';
import dragend            from './methods/dragend';
import updateDragPosition from './methods/updateDragPosition';
import titleClass         from './methods/titleClass';
import handleRowClick     from './methods/handleRowClick';
import handleClickOutside from './methods/handleClickOutside';

export default (
	{
		switchOrder,
		orderColumn,
		rowClass,
		cellClass,
		cellClick,
		allowedActions,
		toggleChecked,
		dragstart,
		dragmove,
		dragend,
		updateDragPosition,
		titleClass,
		handleRowClick,
		handleClickOutside,
	});
