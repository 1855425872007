'use strict';
import data       from './js/data';
import mounted    from './js/mounted';
import components from './js/components';

export default (
	{
		name:'UserMenu',
		data,
		mounted,
		components,
	});
