'use strict';
export default function data ()
	{
		return (
			{
				popup:null,
				mix:null,
				playReady:true,
				playMode:null,
				noPreloader:false,
				lockedReedit:false,
				preloader:false,
				download:{},
				downloadProgress:{},
				tableReload:null,
				mixForSubmit:null,
				allFilesSubmitted:true,
			});
	}
