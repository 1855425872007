'use strict';
export default function data ()
	{
		return (
			{
				files:{},
				success:[],
				error:[],
				dragToggle:true,
			});
	}
