'use strict';
export default (
	{
		deep:true,
		handler (checked)
			{
				let program_managers=[];
				checked.forEach ((c,i)=>
					{
						if (c) program_managers.push (i);
					});
				this.formData.program_managers=program_managers;
			}
	});
