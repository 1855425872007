'use strict';
import init      from './methods/init';
import load      from './methods/load';
import bindTrack from './methods/bindTrack';
import play      from './methods/play';
import pause     from './methods/pause';
import stop      from './methods/stop';
import rewind    from './methods/rewind';
import seek      from './methods/seek';
import dragStart from './methods/dragStart';
import dragMove  from './methods/dragMove';
import dragEnd   from './methods/dragEnd';

export default (
	{
		init,
		load,
		bindTrack,
		play,
		pause,
		stop,
		rewind,
		seek,
		dragStart,
		dragMove,
		dragEnd,
	});
