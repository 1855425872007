'use strict';
export default function switchOrder (column)
	{
		if (this.unsorted) return;
		let order=this.order;
		if (!(column in order)) return;
		switch (order[column])
			{
				case -1:
					order[column]=0;
					break;
				case 0:
					order[column]=1;
					break;
				default:
					order[column]= -1;
			}
		this.$emit ('update:order',order);
	}
