'use strict';
import {findIndex} from "lodash/array";

export default function dragmove (event,id)
	{
		if (!(this.dragEmitter && event.buttons===1 && this.checked[id])) return;
		let from=event.target?.closest ('.playlist-popup-reorder-track');
		let to=event.relatedTarget?.closest ('.playlist-popup-reorder-track');
		if (!(from && to)) return;
		let rect=to.getBoundingClientRect ();
		let height=rect.bottom-rect.top;
		let y=event.clientY-rect.top;
		let tracks=this.formData.tracks.slice ();
		tracks.sort ((a,b)=>a.order-b.order);
		let checked=this.checked;
		let target=findIndex (tracks,{id});
		if (Math.round (y/height))
			{
				while (target>-1 && checked[tracks[target].id]) target--;
				if (checked[tracks[target]]) return;
				let index=target;
				tracks=tracks.slice (target);
				tracks.forEach (track=>
					{
						if (checked[track.id]) track.order=index++;
					});
				tracks.forEach (track=>
					{
						if (!checked[track.id]) track.order=index++;
					});
			} else
			{
				while (target<tracks.length && checked[tracks[target].id]) target++;
				if (checked[tracks[target]]) return;
				let index=target;
				tracks=tracks.slice (0,target+1);
				tracks.reverse ();
				tracks.forEach (track=>
				{
					if (checked[track.id]) track.order=index--;
				});
				tracks.forEach (track=>
				{
					if (!checked[track.id]) track.order=index--;
				});
			}
	}
