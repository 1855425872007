'use strict';
export default function ()
	{
		return (
			{
				pages:
					{
						perPage:Math.abs (this.perPage),
						current:1,
						total:1,
					},
				filter:{},
				invertedFilter:{},
				freeSearch:null,
				allowedControls:{},
				order:{},
				checked:[],
				rows:[],
				counts:
					{
						total:0,
						filtered:0,
					},
				error:null,

				wait:null,
				freeze:false,
				freeSearchPreloader: false,
				preloader:false,
			});
	}
