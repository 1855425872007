'use strict';
export default function deleteMixes ()
	{
		this.lockedReedit=true;
		this.stopPlayMix ().then (()=>
		{
			this.lockedReedit=false;
			this.popup='DeleteMixes';
		});
	}
