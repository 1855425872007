'use strict';
import mixin    from "../../-mixin/mixin";
import computed from './js/computed';

export default (
	{
		mixins:[mixin],
		name:'PopupEmail2Excel',
		computed,
	});
