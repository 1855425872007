'use strict';
export default function data ()
	{
		return (
			{
				preloader:false,
				abortController:new AbortController (),
				audio:
					{
						buffer:null,
						nodes:null,
					},
				canvasBind:{width:0,height:0}
			});
	}
