'use strict';
import PopupEmail2Excel        from '../../../../popups/statistics/PopupEmail2Excel2/PopupEmail2Excel2.vue';
import PopupEditMetadataTitle  from "../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue";
import PopupEditMetadataArtist from "../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue";
import PopupEditMetadataAlbum  from "../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue";
import PopupEditMetadataLabel  from "../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue";
import PopupEditMetadataIsrc   from "../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue";

export default (
	{
		PopupEmail2Excel,
		PopupEditMetadataTitle,
		PopupEditMetadataArtist,
		PopupEditMetadataAlbum,
		PopupEditMetadataLabel,
		PopupEditMetadataIsrc,
	});
