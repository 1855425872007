'use strict';
import PopupCreate              from '../../../../popups/tracks/PopupCreate/PopupCreate.vue';
import PopupEdit                from '../../../../popups/tracks/PopupEdit/PopupEdit.vue';
import PopupDelete              from '../../../../popups/tracks/PopupDelete/PopupDelete.vue';
import PopupAddMoods            from '../../../../popups/tracks/PopupAddMoods/PopupAddMoods.vue';
import PopupAddTags             from '../../../../popups/tracks/PopupAddTags/PopupAddTags.vue';
import PopupEditMetadataTitle   from '../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue';
import PopupEditMetadataArtist  from '../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue';
import PopupEditMetadataAlbum   from '../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue';
import PopupEditMetadataYear    from '../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue';
import PopupEditMetadataLabel   from '../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue';
import PopupEditMetadataComment from '../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue';
import PopupEditMetadataIsrc    from '../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue';
import PopupEditMetadataBpm     from '../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue';
import PopupEditMetadataKey     from '../../../../popups/tracks/PopupEditMetadata/PopupEditMetadata.vue';
import PopupUnbindAll           from '../../../../popups/tracks/PopupUnbindAll/PopupUnbindAll.vue';
import PopupShuffle             from '../../../../popups/tracks/PopupShuffle/PopupShuffle.vue';
import InlinePlayer             from './components/InlinePlayer/InlinePlayer.vue';
import PagePlayer               from './components/PagePlayer/PagePlayer.vue';

export default (
	{
		PopupCreate,
		PopupEdit,
		PopupDelete,
		PopupAddMoods,
		PopupAddTags,
		PopupEditMetadataTitle,
		PopupEditMetadataArtist,
		PopupEditMetadataAlbum,
		PopupEditMetadataYear,
		PopupEditMetadataLabel,
		PopupEditMetadataComment,
		PopupEditMetadataIsrc,
		PopupEditMetadataBpm,
		PopupEditMetadataKey,
		PopupUnbindAll,
		PopupShuffle,
		InlinePlayer,
		PagePlayer,
	});
