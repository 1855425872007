'use strict';
import wrapper from 'bomber-net-axios-wrapper';

export default function load ()
	{
		wrapper.get (`playlist/${this.playlistID}?mixer`).success (success.bind (this)).error (error.bind (this));

		function success ({data})
			{
				let playlist=data ?? {};
				let offset=0;
				playlist?.tracks.forEach (track=>
					{
						track.offset=offset;
						offset+=track.duration;
					});
				playlist.duration=offset;
				this.playlist=playlist;
			}

		function error ({data})
			{
				this.error=data.message;
			}
	}
