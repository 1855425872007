'use strict';
import {kebabCase} from "lodash/string";

export default function cellCustomizer (el,{name,cell})
	{
		switch (name.toLowerCase ())
			{
				case 'status':
					el.className=el.className.replaceAll (/playlist-status-\S+/g,'');
					el.classList.add (`playlist-status-${kebabCase (cell)}`);
					break;
			}
	}
