'use strict';
import components from "../../../../../-mixin/js/components";
import props      from './js/props';
import computed   from './js/computed';
import watch      from './js/watch';
import methods    from './js/methods';
import mounted    from './js/mounted';

export default (
	{
		name:'Curator',
		props,
		computed,
		watch,
		methods,
		mounted,
		components,
	});
