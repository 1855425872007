'use strict';
import load         from './methods/load';
import subscribe    from './methods/subscribe';
import notification from './methods/notification';

export default (
	{
		load,
		subscribe,
		notification,
	});
