'use strict';
import mixin    from '../../-mixin/mixin';
import computed from './js/computed';
import methods  from './js/methods';

export default (
	{
		mixins:[mixin],
		name:'PopupInvite',
		computed,
		methods,
	});
