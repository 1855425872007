<CRMLayout :class="layoutClass">
	<template #logo>
		<img src="./images/logo.png" alt="">
	</template>
	<template #header>
		<div id="header-burger-menu">
			<div :class="menuClosed?'burger-menu-closed':'burger-menu-opened'" class="burger-menu" @click="menuClosed=!menuClosed"><span/></div>
		</div>
		<div v-text="activeChapter.title" id="chapter-title"/>
		<div id="header-expander"/>
		<button v-if="USER.role==='Admin'" id="refresh-archive-btn" @click="refreshArchive"><VueIcon :icon="`\uf021`"/></button>
		<CRMNotifications/>
		<CRMUser/>
	</template>
	<template #menu>
		<CRMMenu :items="chapters.tree"/>
	</template>
	<RouterView :id="viewId" :data-user-role="USER.role"/>
	<CRMChat v-if="!disableChat"/>
	<component v-if="popup" :is="popup" url="https://player.vimeo.com/video/817196671?h=2281409475&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" @close="popup=null"/>
	<PopupArchiveConfirm v-if="popupArchiveConfirm" @close="popupArchiveConfirm=false" @submitSuccess="popupArchiveConfirm=false"/>
</CRMLayout>
