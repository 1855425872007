'use strict';
export default function play ()
	{
		let audioObject=this.audioObject;
		let track=this.track;
		let start=audioObject.startedAt+track.offset;
		let context=audioObject.context;
		let offset=Math.max (0,context.currentTime-start);
		if (offset>track.duration) return;
		let when=Math.max (0,start);
		let source=context.createBufferSource ();
		let audio=this.audio;
		source.buffer=audio.buffer;
		source.start (when,offset);
		source.connect (context.destination);
		audio.nodes={source};
	}
