'use strict';
export default function table ()
	{
		let url=`news_category/table`;
		let data=['#','id','title','description'];
		let columns=
			{
				'#':'#',
				id:{title:'ID',order:0},
				title:{title:'Title',order:0},
				description:{title:'Description',order:0},

			};
		let controls=
			{
				create:'Create',
			};
		let actions=
			{
				edit:{title:'Edit',icon:`\uf044`},
				delete:{title:'Delete',icon:`\uf2ed`},
			};
		return ({url,data,controls,columns,actions});
	}
