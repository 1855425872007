'use strict';
export default function bindTrack (track,index)
	{
		let audioObject=this.audio;
		let classList=[];
		if (!this.audio.mode)
			{
				classList.push (index===this.draggedTrack?'mixer-track-dragging':'mixer-track-draggable');
			}
		return {track,audioObject,'class':classList};
	}
