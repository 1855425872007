'use strict';
import progress   from './methods/progress';
import position   from './methods/position';
import rewind     from './methods/rewind';
import switchMode from './methods/switchMode';

export default (
	{
		progress,
		position,
		rewind,
		switchMode,
	});
