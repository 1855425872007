<div :class="{'channel-view-container-active':active}" class="channel-view-container">
	<div class="channel-view-caption">
		<div v-text="`${name}`"/>
		<div v-if="channel?.show" v-text="`Show: ${channel.show}`"/>
	</div>
	<div class="channel-view-tracks">
		<div class="channel-view-track">
			<div>Previous</div>
			<div v-text="prev"/>
		</div>
		<div class="channel-view-track">
			<div>Current</div>
			<div v-text="curr"/>
		</div>
		<div class="channel-view-track">
			<div>Next</div>
			<div v-text="next"/>
		</div>
	</div>
</div>
