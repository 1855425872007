'use strict';
import load          from './methods/load';
import subscribe     from './methods/subscribe';
import updatePlayNow from './methods/updatePlayNow';

export default (
	{
		load,
		subscribe,
		updatePlayNow,
	});
