'use strict';
export default function popupBind ()
	{
		let show=this.object;
		let id=show?show.id.split ('/')[0]:null;
		let src=show?`show/${id}/edit`:'show/create';
		let dst=show?`show/${id}`:'show';
		let formData=this.formData;
		let method=show?'put':'post';
		return {src,dst,formData,method};
	}
