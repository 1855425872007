'use strict';
export default (
	{
		data:Array,
		columns:Object,
		checkboxes:Boolean,
		checked:Array,
		actions:Object,
		rows:Array,
		order:Object,
		customizers:Object,
		reorder:Boolean,
		unsorted:Boolean,
	});
