'use strict';
import axios from "axios";
import {trim} from "lodash/string";

export default function downloadMix (mixType)
	{
		this.download[mixType]=true;
		this.downloadProgress[mixType]=0;
		let url=`playlist_mix/${this.formData.mixes.filter (mix=>mix.type===mixType)[0].uuid}`;
		axios.get (`${url}/size`).then (startDownload.bind (this));
		
		function startDownload ({data})
			{
				let size=data;
				
				let loaded=0;
				let timer=setInterval (()=>
					{
						let bytes=8192;
						size+=bytes;
						onDownloadProgress.bind (this) ({bytes,fake:true});
					},10);
				axios.get (url,{responseType:'blob',onDownloadProgress:onDownloadProgress.bind (this)}).then (success.bind (this));

				function onDownloadProgress (event)
					{
						let fake=event.fake;
						if (!fake) clearInterval (timer);
						loaded+=event.bytes;
						this.downloadProgress[mixType]=loaded/size;
					}

				function success ({headers,data})
					{
						let link=document.createElement ('a');
						link.download=trim (headers['content-disposition'].split ('=')[1],`'"`);
						link.href=URL.createObjectURL (data);
						link.click ();
						URL.revokeObjectURL (link.href);
						delete (this.download[mixType]);
						delete (this.downloadProgress[mixType]);
					}
			}
	}
