<div id="login-layout">
	<div id="login-form-container">

		<LoginForm v-if="stage==='login'" url="login" @begin="$emit ('begin')" @success="$emit ('success')" @end="$emit ('end')" no-remember>
			<template #before>
				<img id="logo" src="./images/logo.png" alt="">
				<div id="welcome2">Welcome back</div>
			</template>
			<template #submit>Log in</template>
		</LoginForm>

		<VueForm v-else-if="stage==='activate'" :action="activatePath" :data="formData" class="vue-login-form" @success="activated" @error="activationError">
			Activate your account
			<VueFormInput type="password" v-model="formData.password" v-model:e="errors.password">Password</VueFormInput>
			<VueFormInput type="password" v-model="formData.password_confirmation" v-model:e="errors.password_confirmation">Confirm password</VueFormInput>
			<VueFormSubmit>Activate</VueFormSubmit>
		</VueForm>

		<div v-if="browserPopup" class="browser-popup">
			<span>Browser requirements</span>
			<p>Our music dashboard supports <strong>Safari</strong> [version 10.15.7 or higher]/ <strong>Google Chrome</strong> [version 80 or higher]/ <strong>Microsoft Edge</strong> [version 80 or higher] / <strong>Mozilla Firefox</strong> [version 80 or higher].
				<br>
				For the best experience, we highly recommend using up-to-date web browsers.
			</p>
		</div>
	</div>
	<div id="login-splash-container">
		<div>
			<img src="./images/splash.jpg" alt="">
			<div>
				<div>
					<div>music</div>
					<div>Dashboard</div>
				</div>
			</div>
		</div>
		<div>
			<div id="login-splash-text">
				<div>
					Welcome to the official ROVR curation dashboard ! This is where you can access your dedicated
					Program
					Manager space and run your program.
				</div>
				<div>
					One place to easily upload, organize and review every playlist for all your weekly shows. Let’s get
					started
				</div>
			</div>
		</div>
		<img src="./images/rovr-r.svg" alt="" class="rovr-login-letters letter-r-left">
		<img src="./images/rovr-o.svg" alt="" class="rovr-login-letters letter-o">
		<img src="./images/rovr-v.svg" alt="" class="rovr-login-letters letter-v">
		<img src="./images/rovr-r-black.svg" alt="" class="rovr-login-letters letter-r-right">
	</div>
</div>
