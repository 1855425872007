'use strict';
import mixin from '../../-mixin/mixin';
import props from './js/props';

export default (
	{
		mixins:[mixin],
		name:'PopupVideo',
		props,
	});
