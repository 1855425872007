'use strict';
export default function stop ()
	{
		let audio=this.audio;
		let nodes=audio.nodes;
		let source=nodes?.source;
		source?.stop ();
		source?.disconnect ();
		audio.nodes=null;
	}
