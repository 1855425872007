'use strict';
import {isNumber,isObjectLike} from 'lodash/lang';
import {toPairs}               from 'lodash/object';

export default function created ()
	{
		if (!this.unsorted) this.order=toPairs (this.columns).reduce ((order,column)=>
			{
				if (isObjectLike (column[1]) && ('order' in column[1]) && isNumber (column[1].order)) order[column[0]]=column[1].order;
				return order;
			},{});
		this.load ();
	}
