'use strict';
import props      from './js/props';
import methods    from './js/methods';
import created    from './js/created';
import components from './js/components';

export default (
	{
		name:'TableFilters',
		props,
		methods,
		created,
		components,
	});
