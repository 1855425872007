'use strict';
export default function popupBind ()
	{
		let id=this.object?.id;
		let src=id?`schedule/${id}/edit`:'schedule/create';
		let dst=id?`schedule/${id}`:`schedule`;
		let formData=this.formData;
		let method=id?'put':'post';
		return {src,dst,formData,method};
	}
