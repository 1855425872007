'use strict';
let wait;
export default function freeSearch ()
	{
		this.freeSearchPreloader=true;
		if (wait) clearTimeout (wait);
		wait=setTimeout (()=>
			{
				this.freeSearchPreloader=false;
				this.load ();
			},1500);
	}
