<VuePopupEdit v-bind="popupBind" v-on="popupEvents">
	<VueFormInput v-model="formData.title" v-model:e="errors.title">Title</VueFormInput>
	<VueFormInput v-model="formData.artist" v-model:e="errors.artist">Artist</VueFormInput>
	<VueFormInput v-model="formData.album" v-model:e="errors.album">Album</VueFormInput>
	<VueFormInput v-model="formData.year" v-model:e="errors.year">Year</VueFormInput>
	<tempate v-if="USER.role==='Admin'">
		<VueFormInput v-model="formData.bpm" v-model:e="errors.bpm">BPM</VueFormInput>
		<VueFormSelect :options="keys" v-model="formData.key" v-model:e="errors.key" canReset>Key</VueFormSelect>
	</tempate>
	<VueFormInput v-model="formData.comment" v-model:e="errors.comment">Notes</VueFormInput>
	<VueFormInput v-model="formData.label" v-model:e="errors.label">Label</VueFormInput>
	<VueFormInput v-model="formData.isrc" v-model:e="errors.isrc" maxlength="15">ISRC</VueFormInput>
</VuePopupEdit>
