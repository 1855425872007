'use strict';
import {VueFormTabs} from 'bomber-net-vue-forms/elements';
import ChapterTracks from '../../Tracks/Tracks.vue';
import FactoryView   from './components/FactoryView/FactoryView.vue';

export default (
	{
		VueFormTabs,
		ChapterTracks,
		FactoryView,
	});
