'use strict';
import select     from './methods/select';
import uploadBind from './methods/uploadBind';
import fileDone   from './methods/fileDone';

export default (
	{
		select,
		uploadBind,
		fileDone,
	});
