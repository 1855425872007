'use strict';
import activated       from './methods/activated';
import activationError from './methods/activationError';
import detectBrowser   from './methods/detectBrowser';

export default (
	{
		activated,
		activationError,
		detectBrowser,
	});
