'use strict';
export default function table ()
	{
		let url=`client/table`;
		let data=['#','id','name','email','phone','timeshift','device_id','country','os','app_version','genres','notifications'];
		let columns=
			{
				'#':'#',
				id:{title:'ID',order:0},
				name:{title:'Name',order:0},
				email:{title:'email',order:0},
				phone:{title:'Phone',order:0},
				timeshift:{title:'Timeshift',order:0},
				device_id:{title:'Device ID',order:0},
				country:{title:'Country',order:0},
				os:{title:'OS',order:0},
				app_version:{title:'App version',order:0},
				genres:'Genres',
				notifications:'Notifications',
			};
		let actions={view:{title:'Open',icon:`\uf06e`}};
		return ({url,data,columns,actions});
	}
