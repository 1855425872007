'use strict';
export default function popupBind ()
	{
		let id=this.object.id;
		let src=`track/${id}/edit`;
		let dst=`track/${id}`;
		let formData=this.formData;
		let method='put';
		return {src,dst,formData,method};
	}
