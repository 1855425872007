'use strict';
export default function table ()
	{
		let url=`statistics/broadcast`;
		let controls=
			{
				excel:{title:'Excel',icon:`\uf1c3`},
			};
		let data=['in_where','artist','title','length','isrc','album','label','playlist','start','end','listen_sum','track_id'];
		if (USER.role==='Admin') data.push ('tuc');
		let clickable=true;
		let columns=
			{
				in_where:{title:'Show/Mood factory'},
				artist:{title:'Artist',clickable},
				title:{title:'Title',clickable},
				album:{title:'Album',clickable},
				length:{title:'Length'},
				isrc:{title:'ISRC',clickable},
				tuc:{title:'Unique code'},
				label:{title:'Label',clickable},
				playlist:{title:'Playlist'},
				start:{title:'Start'},
				end:{title:'End'},
				listen_sum:{title:'Listeners'},
			};
		if (USER.role!=='Admin') delete (columns.tuc);
		let filters=
			{
				datetime:{title:'Date',type:'datetimerange'},
				country:'Country',
			};
		let hideFreeSearch=true;
		let customizers={cell:this.cellCustomizer};
		return ({url,controls,data,columns,filters,hideFreeSearch,customizers});
	}
