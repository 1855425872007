'use strict';
let oldResponse=null;
let first=true;
export default function reload (response)
	{
		if (first)
			{
				first=false;
				oldResponse=response?JSON.stringify (response):Math.random();
				return;
			}
		let newResponse=response?JSON.stringify (response):Math.random ();
		if (oldResponse!==newResponse)
			{
				oldResponse=newResponse;
				this.$refs.host.load ();
			}
	}
