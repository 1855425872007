'use strict';
export default function clickables ()
	{
		let clickables=[];
		for (let i of Object.keys (this.columns))
			{
				let column=this.columns[i];
				if (typeof (column)==='object' && column.clickable) clickables.push (i);
			}
		return clickables;
	}
