'use strict';
import duration      from './computed/duration';
import durationHMS   from './computed/durationHMS';
import position      from './computed/position';
import positionHm    from './computed/positionHm';
import positionSec   from './computed/positionSec';
import tracksLoaded  from './computed/tracksLoaded';
import bindControls  from './computed/bindControls';
import timelineStyle from './computed/timelineStyle';

export default (
	{
		duration,
		durationHMS,
		position,
		positionHm,
		positionSec,
		tracksLoaded,
		bindControls,
		timelineStyle,
	});
