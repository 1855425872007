'use strict';
import click     from './methods/click';
import resize    from './methods/resize';
import mousemove from './methods/mousemove';

export default (
	{
		click,
		resize,
		mousemove,
	});
