'use strict';
import './bootstrap';
import {createApp} from 'vue';
import crm         from '../vue/crm/crm.vue';
import mixin       from '../vue/mixin/mixin';
import router      from '../vue/router/router';

window.Router=router;

createApp (crm).mixin (mixin).use (router).mount ('body');
