'use strict';

export default function table ()
	{
		if (!this.filtersLoaded) return false;
		let url=`playlist/table`;
		let data=['#','show','id','title','last_modified','program_manager','tracks_count','duration','status','comment','mixes'];
		switch (USER.role)
			{
				case 'Admin':
				case 'Program manager':
					data.push ('curator');
					break;
				case 'Curator':
					break;
			}
		let controls={create:'Create'};
		let filters=
			{
				show:{type:'select',title:'Show',options:this.shows,optionsOrderer:this.optionsOrderer},
				program_manager:{type:'select',title:'Program manager',options:this.program_managers,optionsOrderer:this.optionsOrderer},
				status:{type:'select',title:'Status',options:this.statuses},
			};
		let query=new URL (window.location).searchParams;
		let status=query.get ('status');
		if (status)
			{
				switch (status)
					{
						case 'ready':
							filters.status.default='readyForSubmit';
							break;
						case 'playlist':
						default:
							if (['draft','submitted','scheduled'].includes (status)) filters.status.default=status;
					}
			}
		let show=query.get ('show');
		if (show) filters.show.default=show;
		let order=USER.role==='Admin'?0:undefined;
		let columns=
			{
				'#':{title:'#',clickable:true},
				id:{title:'ID',order,clickable:true},
				show:{title:'Show',order,clickable:true},
				title:{title:'Title',order:0,clickable:true},
				last_modified:{title:'Last modified',order,clickable:true},
				program_manager:{title:'Managed by',order:0,clickable:true},
				curator:{title:'Curator',clickable:true},
				tracks_count:{title:'Tracks',clickable:true},
				duration:{title:'Length',clickable:true},
				status:{title:'Status',clickable:true},
				comment:{title:'Notes',order:0,clickable:true},
				submit:{title:'',isSlot:true},
			};
		if (USER.role==='Program manager') delete (columns.comment.order);
		let actions=
			{
				view:{title:'Open',icon:`\uf06e`},
				edit:{title:'Edit',icon:`\uf044`},
				allowForSchedule:{title:'Allow playlist for schedule',icon:`\uf274`},
				delete:{title:'Delete',icon:`\uf2ed`},
				tracklist:{title:'Tracklist',icon:`\uf1c3`},
				metadata:{title:'Fill metadata',icon:`\uf570`},
			};
		if (USER.role!=='Admin') delete actions.reorder;
		let customizers={cell:this.cellCustomizer};
		let perPage=USER.role==='Program manager'?-100:100;
		return ({url,data,filters,controls,columns,actions,customizers,perPage});
	}
