'use strict';
export default function data ()
	{
		return (
			{
				chapters:
					{
						tree:{},
						map:{},
					},
				activeChapter:{},
				activePath:null,
				popup:null,
				menuClosed:true,
				popupArchiveConfirm:false,
				disableChat:import.meta.env.VITE_DISABLE_CHAT,
			});
	}
