'use strict';
import load                  from './methods/load';
import loadUser              from './methods/loadUser';
import socketConnect         from './methods/socketConnect';
import socketDisconnect      from './methods/socketDisconnect';
import unauthorized          from './methods/unauthorized';
import authenticationTimeout from './methods/authenticationTimeout';

export default (
	{
		load,
		loadUser,
		socketConnect,
		socketDisconnect,
		unauthorized,
		authenticationTimeout,
	});
