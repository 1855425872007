'use strict';
export default function mounted ()
	{
		let Tawk_API={};
		let Tawk_LoadStart=new Date ();
		(function ()
			{
				let s1=document.createElement ('script'),s0=document.getElementsByTagName ('script')[0];
				s1.async=true;
				s1.src='https://embed.tawk.to/642165224247f20fefe830c3/1gsh76484';
				s1.charset='UTF-8';
				s1.setAttribute ('crossorigin','*');
				s0.parentNode.insertBefore (s1,s0);
			}) ();
	}
