'use strict';
export default function pause ()
	{
		let audio=this.audio;
		switch (audio.mode)
			{
				case 'running':
					audio.context.suspend ();
					break;
				case 'suspended':
					audio.context.resume ();
					break;
			}
	}
