<VuePopupEdit v-bind="popupBind" v-on="popupEvents" class="jingle-popup-edit">
	<VueFormInput v-model="formData.title" v-model:e="errors.title">Title</VueFormInput>
	<VueFormInput v-model="formData.description" v-model:e="errors.description" type="area">Description</VueFormInput>
	<div class="jingle-popup-preview view-photo-wrapper">
		<div class="jingle-popup-audio">
			<audio v-if="jingle" :src="jingle" controls preload="auto"/>
			<VueIcon v-if="formData.jingle" :icon="`\uf2ed`" @click="formData.jingle=null"/>
		</div>
		<VueFormFile @select="selectJingle" @click="delete (errors.jingle)" accept="audio/mpeg" class="view-choose-file">
			<VueIcon :icon="`\uf0ee`"/>
			Select jingle
		</VueFormFile>
	</div>
</VuePopupEdit>
