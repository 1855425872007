'use strict';
import inject     from './js/inject';
import computed   from './js/computed';
import methods    from './js/methods';
import directives from './js/directives';
import components from './js/components';

export default (
	{
		inject,
		computed,
		methods,
		directives,
		components,
	});
