<VuePopupEdit v-bind="popupBind" v-on="popupEvents">
	<VueFormInput v-if="USER.role!=='Admin'" v-model="formData.current" v-model:e="errors.current" type="password">
		Current password
	</VueFormInput>
	<VueFormInput v-model="formData.password" v-model:e="errors.password" type="password">
		New password
	</VueFormInput>
	<VueFormInput v-model="formData.password_confirmation" v-model:e="errors.password_confirmation" type="password">
		Confirm
	</VueFormInput>
</VuePopupEdit>
