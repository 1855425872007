'use strict';
export default function table ()
	{
		let url=`jingle/table`;
		let data=['#','id','duration','title','description','jingle'];
		let columns=
			{
				'#':'#',
				id:{title:'ID',order:0},
				duration:{title:'Duration',order:0},
				title:{title:'Title',order:0},
				description:{title:'Description',order:0},
				jingle:'Jingle'
			};
		let controls=
			{
				create:'Create',
			};
		let actions=
			{
				edit:{title:'Edit',icon:`\uf044`},
			};
		return ({url,data,controls,columns,actions});
	}
