'use strict';
import PopupView             from '../../../../popups/playlists/PopupView/PopupView.vue';
import PopupEdit             from '../../../../popups/playlists/PopupEdit/PopupEdit.vue';
import PopupDelete           from '../../../../popups/playlists/PopupDelete/PopupDelete.vue';
import PopupAllowForSchedule from '../../../../popups/playlists/PopupAllowForSchedule/PopupAllowForSchedule.vue';

export default (
	{
		PopupView,
		PopupEdit,
		PopupDelete,
		PopupAllowForSchedule,
	});
